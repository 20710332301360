<template>
  <el-dialog
    :visible="visible"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    :show-close="false"
    :modal-append-to-body="false"
    top="0"
    :custom-class="`dialog ${size}`"
  >
    <div class="dialog__close" @click="$emit('close')">
      <div class="close__icon" />
    </div>
    <div class="dialog__content">
      <slot name="content" />
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "ModalWrapper",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    theme: {
      type: String,
      default: "",
    },
    size: {
      type: String,
      default: "",
    },
  },
}
</script>

<style lang="sass" scoped>
.el-dialog__wrapper.dark-form
  background: rgba(8, 15, 17, 0.58)
  ::v-deep .dialog
    background: #0D1A1B

.el-dialog__wrapper.light-form
  background: rgba(33, 50, 50, 0.22)
  ::v-deep .dialog
    background: $textWhite

::v-deep .dialog
  position: absolute
  top: 50%
  left: 50%
  transform: translate(-50%, -60%)
  width: 480px
  border-radius: 30px
  @media (max-width: 1023px)
    width: calc(100% - 4rem)
    max-width: 480px

  &.small
    width: 440px
    @media (max-width: 1023px)
      width: calc(100% - 4rem)
      max-width: 600px
  &.track
    width: 660px
    @media (max-width: 1023px)
      width: calc(100% - 4rem)
      max-width: 660px

  .el-dialog__header
    display: none
  .el-dialog__body
    padding: 0

  &__close
    position: absolute
    top: 20px
    right: 20px
    cursor: pointer
    .close__icon
      width: 2.4rem
      height: 2.4rem
      background: url('~@/assets/img/new-close.svg')
      background-size: cover

  &__content
    width: 100%
    padding: 40px
    border-radius: 30px
    @media (max-width: 1023px)
      padding: 2.2rem
</style>
