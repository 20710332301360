<template>
  <footer ref="anchor" class="footer">
    <div class="footer__company">
      <div
        v-for="field in content.company"
        :key="field"
        class="footer__company-item"
      >
        {{ field }}
      </div>
    </div>
    <div class="footer__copyright">
      <div>{{ content.copyright }}&nbsp;{{ year }}</div>
      <a href="/policy.pdf" target="_blank" class="footer__copyright-link"
        >Политика конфиденциальности</a
      >
    </div>
  </footer>
</template>

<script>
import { footerBlock } from "@/content/data"
import offsetMixin from "@/mixins/offsetMixin"
export default {
  name: "FooterComponent",
  mixins: [offsetMixin],
  data() {
    return {
      content: footerBlock,
    }
  },
  computed: {
    year() {
      let date = new Date()
      return date.getFullYear()
    },
  },
}
</script>

<style lang="sass" scoped>
.footer
  position: relative
  font-family: $secondaryFont
  color: $textGray
  z-index: 1
  &__company
    padding: 40px $desktopHorizontalPaddings 30px
    font-size: 14px
    line-height: 18px
    border-bottom: 1px solid #272F32
    @media (max-width: 1023px)
      padding: 32px $mobileHorizontalPaddings 24px
    &-item
      width: 488px
      margin-bottom: 8px
      @media (max-width: 1023px)
        width: 100%
        max-width: 488px
  &__copyright
    display: flex
    justify-content: space-between
    padding: 20px $desktopHorizontalPaddings
    font-size: 12px
    line-height: 16px
    @media (max-width: 1023px)
      flex-direction: column
      padding: 20px $mobileHorizontalPaddings
    &-link
      width: fit-content
      color: $textGray
      text-decoration: none
      @media (max-width: 1023px)
        margin-top: 4px
      &:hover
        color: $textLightWhite
        border-bottom: 1px solid $textLightWhite
</style>
