import { menuHeight } from "@/helpers/view-constatns"

export default {
  mounted() {
    this.$store.dispatch("setOffset", {
      block: this.$options.name,
      value: this.$refs.anchor.offsetTop - menuHeight(),
    })
  },
}
