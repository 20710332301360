export default {
  state: {
    blockOffset: {
      GlonassServices: 0,
      GlonassTariffs: 0,
      GlonassOferta: 0,
      FooterComponent: 0,
    },
  },
  getters: {
    getOffsets(state) {
      return state.blockOffset
    },
  },
  mutations: {
    SET_OFFSET(state, offset) {
      state.blockOffset[offset.block] = offset.value
    },
  },
  actions: {
    setOffset({ commit }, offset) {
      commit("SET_OFFSET", offset)
    },
  },
}
