<template>
  <modal-wrapper :visible="visible" size="track" v-on="$listeners">
    <template slot="content">
      <track-form :plate-options="options" :truck-list="list" />
    </template>
  </modal-wrapper>
</template>

<script>
import ModalWrapper from "@/components/common/Modals/ModalWrapper"
import TrackForm from "@/components/common/Form/Track/TrackForm"

export default {
  name: "ModalTrack",
  components: {
    ModalWrapper,
    TrackForm,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    list: {
      type: Array,
      default: () => [],
    },
    options: {
      type: Array,
      default: () => [],
    },
  },
}
</script>
