<template>
  <mobile-cabinet-layout v-loading="loading">
    <div slot="content" class="content">
      <yandex-one-route v-if="pointList.length" :list="pointList" />
      <glonass-empty
        v-if="!pointList.length && !loading"
        title="Нет данных за указанную дату"
      />
      <div class="content__detail flex">
        <TruckCard
          :truck="activeTruck"
          class="content__detail-card"
          @set-status="closeTrack"
        />
        <div class="aside__date">
          <span class="aside__date-title">Дата трека:&nbsp;</span>
          <span class="aside__date-content">{{ trackDate }}</span>
        </div>
      </div>
    </div>
    <main-button
      slot="footer"
      title="Очистить трек"
      :icon="true"
      theme="light"
      class="track-button"
      @button-clicked="closeTrack"
    />
  </mobile-cabinet-layout>
</template>

<script>
import MobileCabinetLayout from "@/layouts/MobileCabinetLayout"
import trackMixin from "@/views/TrackPage/trackMixin"
export default {
  name: "MobileTrackView",
  components: { MobileCabinetLayout },
  mixins: [trackMixin],
}
</script>

<style lang="sass" scoped>
@import "@/assets/styles/spinner-mixin.sass"
@include light-spiner
@import "./date-styles"
.content
  height: 100%
  &__detail
    position: fixed
    bottom: 8rem
    left: 0
    right: 0
    height: 166px
    flex-direction: column
    align-items: center
    padding: 24px 2.4rem
    background: $textSecondary
    box-shadow: 0 0 10px rgba(132, 132, 132, 0.15)
    border-radius: 20px 20px 0 0

    &-card
      width: 100%
      max-width: 500px
      ::v-deep .card
        margin-bottom: 0
        border: 1px solid $special

.track-button
  width: 100%
  max-width: 500px
  height: 4.8rem
</style>
