<template>
  <section class="landing">
    <fixed-menu-desktop @button-clicked="showForm" />
    <fixed-menu-mobile @button-clicked="showForm" />
    <header-component />
    <glonass-services />
    <glonass-tariffs />
    <glonass-oferta />
    <footer-component />
    <modal-auth
      :visible="modalFormOpened"
      class="dark-form"
      @requested="modalFormOpened = false"
      @close="closeForm"
    />
    <div
      :style="{ 'background-image': 'url(' + imageUrl(content.image) + ')' }"
      class="landing__image"
    />
  </section>
</template>

<script>
import FixedMenuDesktop from "@/components/landing/menu/FixedMenuDesktop"
import FixedMenuMobile from "@/components/landing/menu/FixedMenuMobile"
import FooterComponent from "@/components/landing/Footer.vue"
import HeaderComponent from "@/components/landing/Header.vue"
import GlonassServices from "@/components/landing/GlonassServices"
import GlonassTariffs from "@/components/landing/GlonassTariffs"
import GlonassOferta from "@/components/landing/GlonassOferta"
import ModalAuth from "@/components/common/Modals/ModalAuth"
import { imageUrl } from "@/helpers/imageLoading"
import { pageBlock } from "@/content/data"

export default {
  name: "LandingPage",
  components: {
    FixedMenuDesktop,
    FixedMenuMobile,
    HeaderComponent,
    GlonassServices,
    GlonassTariffs,
    GlonassOferta,
    FooterComponent,
    ModalAuth,
  },
  data() {
    return {
      modalFormOpened: false,
      content: pageBlock,
      imageUrl,
    }
  },
  methods: {
    // открытие модальной формы заявки
    showForm() {
      this.modalFormOpened = true
    },
    closeForm() {
      this.modalFormOpened = false
      document.body.style.overflow = "auto"
    },
  },
}
</script>

<style lang="sass" scoped>
.landing
  position: relative
  background: $bgColor
  &__image
    position: absolute
    bottom: 0
    left: 0
    right: 0
    height: 600px
    background-position: bottom
    background-repeat: no-repeat

::v-deep .v-modal
  background: rgba(25, 31, 38, 0.7)

.dark-form ::v-deep .dialog__content
  background: #0D1A1B
</style>
