<template>
  <modal-wrapper :visible="visible" v-on="$listeners">
    <template slot="content">
      <auth-form v-if="authStep === 'login'" @request-successful="toConfirm" />
      <confirm-form
        v-else
        :login="loginData"
        @change-phone="authStep = 'login'"
      />
    </template>
  </modal-wrapper>
</template>

<script>
import ModalWrapper from "@/components/common/Modals/ModalWrapper"
import AuthForm from "@/components/common/Form/Auth/AuthForm"
import ConfirmForm from "@/components/common/Form/Auth/ConfirmForm"

export default {
  name: "ModalAuth",
  components: {
    ModalWrapper,
    AuthForm,
    ConfirmForm,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      authStep: "login",
      loginData: {},
    }
  },
  methods: {
    toConfirm(data) {
      this.loginData = data
      this.authStep = "submit"
    },
  },
}
</script>
