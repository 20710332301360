<template>
  <desktop-cabinet-layout v-loading="loading">
    <main-button
      v-if="truckList.length"
      slot="button"
      title="Построить трек"
      theme="light"
      class="track-button"
      @button-clicked="startTrack"
    />
    <div slot="aside" class="aside" @click="activeTruck = ''">
      <template v-if="truckList.length">
        <TruckCard
          v-for="(truck, index) in truckList"
          :key="index"
          :truck="truck"
          :active="truck.auto_num === activeTruck"
          :hovered="truck.auto_num === hoveredTruck"
          @clicked="activeTruck = truck.auto_num"
          @hovered="hoveredTruck = truck.auto_num"
          @unhovered="hoveredTruck = ''"
          @set-status="setTruckStatus"
        />
      </template>
    </div>
    <div slot="main" class="map" v-loading="loading">
      <yandex-map-component
        v-if="mapList.length"
        :points="mapList"
        :active="activeTruck"
        :hovered="hoveredTruck"
        @clicked="activeTruck = $event"
        @hovered="hoveredTruck = $event"
        @unhovered="hoveredTruck = ''"
      />
      <glonass-empty v-if="!mapList.length && !loading" :title="emptyText" />
    </div>

    <modal-track
      slot="modal"
      :visible="modalTrackOpened"
      :list="truckList"
      :options="plateList"
      class="light-form"
      @close="modalTrackOpened = false"
    />
  </desktop-cabinet-layout>
</template>

<script>
import DesktopCabinetLayout from "@/layouts/DesktopCabinetLayout"
import glonassMixin from "@/views/GlonassPage/glonassMixin"

export default {
  name: "DesktopGlonassView",
  components: {
    DesktopCabinetLayout,
  },
  mixins: [glonassMixin],
}
</script>

<style lang="sass" scoped>
@import "@/assets/styles/spinner-mixin.sass"
@include light-spiner
.track-button
  margin-left: 4rem
  padding: 1.2rem 2.4rem
  font-size: 1.4rem
.aside
  min-height: 100%
  padding: 27px 43px 84px
.map
  height: 100%
  &__empty
    height: 100%
</style>
