<template>
  <div>
    <div
      :class="['input', inputStatus, theme, { error: hasError }]"
      @click="start"
      @keyup.tab="start"
    >
      <div :class="['input__content', inputStatus, theme, { error: hasError }]">
        <div :class="['input__content-prompt', inputStatus, theme]">
          {{ titleText }}
        </div>
        <input
          ref="input"
          v-model="inputContent"
          :class="[
            'input__content-input',
            inputStatus,
            theme,
            { error: hasError },
          ]"
          @blur="inputChanged"
        />
      </div>
    </div>
    <transition>
      <div v-if="hasError" class="input__error">
        <div class="input__error-image">
          <img src="@/assets/img/new-error.svg" alt="phone" />
        </div>
        <p class="input__error-text">{{ errorText }}</p>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "DoubleInput",
  props: {
    title: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    theme: {
      type: String,
      default: "light",
    },
    errorText: {
      type: String,
      default: "",
    },
    hasError: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      inputContent: "",
      inputStatus: "",
    }
  },
  computed: {
    titleText() {
      return this.inputStatus.length ? this.title : this.placeholder
    },
  },
  methods: {
    start() {
      this.inputStatus = "active"
      this.$refs.input.focus()
      this.$emit("input-focus", this.inputContent)
    },
    inputChanged() {
      if (!this.inputContent.length) {
        this.inputStatus = ""
      }
      this.$emit("input-changed", this.inputContent)
    },
  },
}
</script>

<style lang="sass" scoped>
.input
  display: flex
  width: 100%
  height: 5rem
  margin-bottom: 4rem
  border-radius: 1rem
  cursor: pointer
  &.light
    background: $textSecondary
  &.dark
    background: #162223
  &.error
    border: 1px solid $errorColor

  &__content
    flex: 1
    display: flex
    flex-direction: column
    justify-content: center
    padding: 0.6rem 1.2rem
    border-radius: 1rem

    &-input
      width: 100%
      height: 0
      padding: 0
      border-width: 0
      outline: none
      font-family: $secondaryFont
      font-weight: 400
      font-size: 0
      transition: height .3s ease
      color: $textSecondary
      &.light
        background: $textSecondary
      &.dark
        background: #162223
      &.active
        height: 2.5rem
        font-size: 1.4rem

    &-prompt
      font-family: $secondaryFont
      font-weight: 400
      font-size: 1.4rem
      color: $textGray
      transition: all .3s ease
      &.active
        margin-bottom: .4rem
        font-size: 1.2rem
        line-height: 1.6rem

  &__error
    display: flex
    align-items: center
    height: 4rem
    margin-top: -4rem
    font-family: $secondaryFont
    font-size: 1.2rem
    line-height: 1.6rem
    color: $errorColor
    &-image
      margin-top: 2px
      margin-right: 4px
</style>
