<template>
  <section class="menu">
    <div
      v-for="(item, index) in menuItems"
      :key="index"
      :class="['menu__item flex-center', { active: index === mode }]"
      @click="setMode(index)"
    >
      {{ item }}
    </div>
  </section>
</template>

<script>
import { mapGetters, mapMutations } from "vuex"
export default {
  name: "MobileMenu",
  data() {
    return {
      menuItems: ["Списком", "На карте"],
    }
  },
  computed: {
    ...mapGetters({
      mode: "getMenuMode",
    }),
  },
  methods: {
    ...mapMutations({
      setMode: "setMenuMode",
    }),
  },
}
</script>

<style lang="sass" scoped>
.menu
  position: fixed
  top: 7.6rem
  left: 50%
  transform: translateX(-50%)
  display: flex
  justify-content: space-between
  width: calc(100vw - 5rem)
  max-width: 500px
  height: 4.2rem
  padding: 0.4rem
  background: $textWhite
  border: 1px solid $scrollbarGray
  border-radius: 12px
  z-index: 100
  &__item
    width: 49%
    height: 100%
    font-family: $secondaryFont
    font-size: 1.4rem
    font-weight: 700
    color: $secondaryBlack
    border: 1px solid transparent
    border-radius: 8px
    cursor: pointer
    &:hover
      border: 1px solid $special

    &.active
      background: #F0FBF7
      color: $special
      border: 1px solid $special
</style>
