<template>
  <div class="picker">
    <el-date-picker
      ref="picker"
      v-model="value"
      type="date"
      value-format="yyyy-MM-dd"
      :editable="false"
      :clearable="false"
      :placeholder="placeholder"
      :picker-options="pickerOptions"
      :append-to-body="false"
      @input="handleSelect"
      @change="onChange"
      @focus="onFocus"
      @blur="onBlur"
      format="dd.MM.yyyy"
      :class="{ filled: value }"
    />
    <div v-if="value" class="font2-12-16 picker__title">{{ title }}</div>
    <div :class="['picker__icon', iconClass]" @click="iconClick" />
  </div>
</template>

<script>
import { isFuture } from "@/helpers/date"

export default {
  name: "DatePicker",
  props: {
    title: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      value: "",
      pickerOptions: {
        firstDayOfWeek: 1,
        disabledDate(time) {
          return isFuture(time)
        },
      },
      iconClass: "",
    }
  },
  methods: {
    handleSelect(event) {
      this.$emit("input", event)
    },
    onChange() {
      this.iconClass = ""
    },
    onBlur() {
      this.blurWhenFilled = !!this.value
      this.iconClass = ""
    },
    onFocus() {
      this.iconClass = "open"
      this.$emit("start")
    },
    iconClick() {
      this.iconClass = "open"
      this.$refs.picker.focus()
    },
  },
}
</script>

<style lang="sass" scoped>
.picker
  position: relative
  &__title
    position: absolute
    top: 10px
    left: 20px
    font-size: 12px
    line-height: 16px
    color: $textGray

  &__icon
    position: absolute
    top: 0
    right: 17px
    width: 50px
    height: 50px
    background-position: center
    background-repeat: no-repeat
    transition: transform 0.3s ease
    cursor: pointer
    background-image: url("~@/assets/img/angle-down-icon.svg")
    &.open
      transform: rotate(180deg)

  ::v-deep .el-date-editor.el-input
    display: block
    width: auto !important
    padding-left: 0

  ::v-deep .el-date-editor.el-input.el-input--prefix.el-input--suffix.el-date-editor--date
    .el-input__inner
      height: 50px
      line-height: 50px
      padding-left: 60px
      background: $textSecondary
      border: none
      border-radius: 10px
      color: $primaryBlack
      font-family: "Onest", sans-serif

      &::placeholder
        font-family: $secondaryFont
        color: $textGray
        padding-left: -30px

    .el-input__prefix
      background-color: #eef0f4
      width: 50px
      height: 50px
      border-radius: 10px 0 0 10px
      left: 0

      .el-input__icon
        line-height: 50px

    &.filled
      .el-input__inner
        padding-top: 20px
        font-size: 14px
        font-family: $secondaryFont

    &.mobile
      .el-picker-panel.el-date-picker.el-popper
        position: fixed !important
        top: 40% !important
        left: 0 !important
        right: 0 !important
        bottom: 0
        width: 100%
        border-radius: 16px 16px 0 0
        .el-picker-panel__content
          width: 90%
          margin: 0 auto
          .el-date-table th
            text-align: center

        .popper__arrow
          display: none

  ::v-deep .el-picker-panel.el-date-picker.el-popper
    top: auto !important
    left: auto !important
    border-radius: 16px
    box-shadow: 0 0 10px rgba(132, 132, 132, 0.15)
    .el-date-picker__header
      padding-top: 14px
      &-label
        color: $special
        padding: 0 2px

      &-label + .el-date-picker__header-label
        font-weight: 400

      .el-picker-panel__icon-btn.el-date-picker__prev-btn.el-icon-d-arrow-left,
      .el-picker-panel__icon-btn.el-date-picker__next-btn.el-icon-d-arrow-right
        display: none

      .el-picker-panel__icon-btn.el-date-picker__prev-btn.el-icon-arrow-left,
      .el-picker-panel__icon-btn.el-date-picker__next-btn.el-icon-arrow-right
        color: $special
        font-size: 18px
        font-weight: 600
        padding: 6px
        border: 1px solid #eef0f4
        border-radius: 10px
        margin: 0

    .el-date-table th
      font-weight: 400
      font-size: 16px
      &:nth-child(6),
      &:last-child
        color: $errorColor


    .el-picker-panel
      line-height: 14px

    .el-date-table td
      &.available
        span
          font-weight: 400
          font-size: 16px

        &:hover
          color: $special

      &.current span
        background-color: $special

      &.today
        background-color: transparent
        span
          color: $special
          font-weight: 400
          font-size: 16px

        &.current span
          color: $textWhite

      &.next-month,
      &.prev-month
        font-weight: 400
        font-size: 16px

.picker ::v-deep .el-date-editor.el-input.el-input--prefix.el-input--suffix.el-date-editor--date .el-input__inner
  padding-left: 20px
.picker ::v-deep .el-input__prefix
  display: none
</style>
