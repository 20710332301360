<template>
  <mobile-cabinet-layout v-loading="loading">
    <div slot="content" class="content">
      <mobile-menu v-if="truckList.length" />
      <template v-if="truckList.length">
        <yandex-map-component
          v-if="mode"
          :points="mapList"
          :active="activeTruck"
          @clicked="setActive"
        />
        <div v-else class="content__list">
          <TruckCard
            v-for="(truck, index) in truckList"
            :key="index"
            :truck="truck"
            :active="truck.auto_num === activeTruck"
            :hovered="truck.auto_num === hoveredTruck"
            @clicked="activeTruck = truck.auto_num"
            @set-status="setTruckStatus"
          />
        </div>
      </template>
      <glonass-empty v-if="!mapList.length && !loading" :title="emptyText" />

      <!--      карточка активной машины (только для режима Yandex-карты)-->
      <div
        v-if="mode"
        :class="['content__detail flex-center', { visible: activeTruckCard }]"
      >
        <TruckCard
          v-if="activeTruckCard"
          :truck="activeTruckCard"
          class="content__detail-card"
          @set-status="setActiveTruckStatus"
        />
      </div>
    </div>
    <main-button
      v-if="truckList.length"
      slot="footer"
      title="Построить трек"
      theme="light"
      class="track-button"
      @button-clicked="startTrack"
    />
    <modal-track
      slot="modal"
      :visible="modalTrackOpened"
      :list="truckList"
      :options="plateList"
      class="light-form"
      @close="modalTrackOpened = false"
    />
  </mobile-cabinet-layout>
</template>

<script>
import MobileCabinetLayout from "@/layouts/MobileCabinetLayout"
import MobileMenu from "@/components/glonass/MobileMenu"
import glonassMixin from "@/views/GlonassPage/glonassMixin"
import { mapGetters } from "vuex"

export default {
  name: "MobileGlonassView",
  components: { MobileCabinetLayout, MobileMenu },
  mixins: [glonassMixin],
  computed: {
    ...mapGetters({
      mode: "getMenuMode",
    }),
    activeTruckCard() {
      if (this.activeTruck.length) {
        return this.truckList.find((item) => item.auto_num === this.activeTruck)
      }
      return null
    },
  },
  watch: {
    mode() {
      // если при переходе на карту активна карточка блокированного авто - сбрасываем активность
      if (this.mode) {
        let truck = this.truckList.find(
          (item) => item.auto_num === this.activeTruck
        )
        if (truck.status === "blocked") this.activeTruck = ""
      }
    },
  },
  methods: {
    setActive(id) {
      this.activeTruck = id
    },
    // уходим на список при изменении Глонасс-статуса выделенной на карте машины
    setActiveTruckStatus() {
      window.location.reload()
    },
  },
}
</script>

<style lang="sass" scoped>
@import "@/assets/styles/spinner-mixin.sass"
@include light-spiner
.content
  height: 100%
  &__list
    padding: 8.2rem 2rem 2rem
  &__detail
    position: fixed
    bottom: calc(-132px + 8rem)
    left: 0
    right: 0
    height: 132px
    padding: 24px 2.4rem
    background: $textSecondary
    box-shadow: 0 0 10px rgba(132, 132, 132, 0.15)
    border-radius: 20px 20px 0 0
    transition: bottom 0.5s ease
    &.visible
      bottom: 8rem

    &-card
      width: 100%
      max-width: 500px
      ::v-deep .card
        margin-bottom: 0
        border: 1px solid $special
      //border-radius: 12px
.track-button
  width: 100%
  max-width: 500px
  height: 4.8rem
</style>
