import { imageUrl } from "@/helpers/imageLoading"

export default {
  data() {
    return {
      currentImage: "",
    }
  },
  created() {
    this.selectBgImage()
    window.addEventListener("resize", this.selectBgImage)
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.selectBgImage)
  },
  methods: {
    selectBgImage() {
      this.currentImage =
        document.documentElement.clientWidth > 768
          ? imageUrl(this.content.imageDesktop)
          : imageUrl(this.content.imageMobile)
    },
  },
}
