<template>
  <section ref="anchor" class="services">
    <div class="font1-56-62 services__title">{{ content.title }}</div>
    <div class="services__tiles">
      <div v-for="(tile, index) in content.tiles" :key="index" class="tile">
        <div>
          <div
            class="tile__icon"
            :style="{ 'background-image': 'url(' + imageUrl(tile.icon) + ')' }"
          />
          <div class="tile__type">{{ tile.type }}</div>
          <div class="tile__title">{{ tile.title }}</div>
          <div
            v-for="(feature, index) in tile.list"
            :key="index"
            class="tile__feature"
          >
            {{ feature }}
          </div>
        </div>
        <div>
          <div v-html-safe="tile.price" class="tile__price" />
          <div
            v-if="tile.comment"
            v-html-safe="tile.comment"
            class="tile__comment"
          />
        </div>
      </div>
    </div>
    <div
      class="services__spot"
      :style="{ 'background-image': 'url(' + currentImage + ')' }"
    />
  </section>
</template>

<script>
import { servicesBlock } from "@/content/data"
import offsetMixin from "@/mixins/offsetMixin"
import resizeMixin from "@/mixins/resizeMixin"
import { imageUrl } from "@/helpers/imageLoading"

export default {
  name: "GlonassServices",
  mixins: [offsetMixin, resizeMixin],
  data() {
    return {
      content: servicesBlock,
      imageUrl,
    }
  },
}
</script>

<style lang="sass" scoped>
.services
  position: relative
  padding: 0 $desktopHorizontalPaddings $desktopVerticalPaddings
  @media (max-width: 1023px)
    padding: 0 $mobileHorizontalPaddings $mobileVerticalPaddings
  &__title
    margin-bottom: 6rem
    @media (max-width: 1023px)
      margin-bottom: 0.8rem
  &__tiles
    display: flex
    justify-content: space-between
    @media (max-width: 1023px)
      flex-direction: column

    .tile
      position: relative
      display: flex
      flex-direction: column
      justify-content: space-between
      width: 48.3%
      padding: 5.6rem 4.8rem 6rem
      background: rgba(26, 51, 51, 0.2)
      border: 1px solid rgba(211, 255, 233, 0.06)
      backdrop-filter: blur(5px)
      border-radius: 40px
      @media (max-width: 1023px)
        width: 100%
        margin-top: 3.2rem
        padding: 2.4rem
      &__icon
        position: absolute
        top: 4.8rem
        right: 3.6rem
        width: 9rem
        height: 9rem
        background-size: cover
        @media (max-width: 1023px)
          top: 2.4rem
          right: 2.4rem
          width: 6rem
          height: 6rem
      &__type
        margin-bottom: 1.6rem
        font-family: $secondaryFont
        font-size: 1.4rem
        line-height: 2.4rem
        letter-spacing: 0.05em
        color: $textSecondary
        @media (max-width: 1023px)
          margin-bottom: 0.8rem
          font-size: 1.2rem
      &__title
        margin-bottom: 2.4rem
        font-size: 4.8rem
        line-height: 5.6rem
        letter-spacing: -0.005em
        @media (max-width: 1919px)
          font-size: 3.6rem
          line-height: 4.4rem
        @media (max-width: 1023px)
          font-size: 2.4rem
          line-height: 3.2rem
      &__feature
        margin-top: 1.2rem
        font-family: $secondaryFont
        font-size: 1.6rem
        line-height: 2.2rem
        color: $scrollbarGray
        @media (max-width: 1023px)
          font-size: 1.4rem
          line-height: 1.8rem
      &__price
        margin-top: 4.8rem
        font-size: 3.2rem
        line-height: 4rem
        color: $textAccent
        letter-spacing: -0.005em
        @media (max-width: 1023px)
          margin-top: 3.2rem
          font-size: 2.2rem
          line-height: 3rem
      &__comment
        width: 80%
        margin-top: 1.6rem
        font-size: 1.2rem
        line-height: 1.6rem
        color: $commentColor
        @media (max-width: 1023px)
          width: 100%
          margin-bottom: 0.8rem

  &__spot
    position: absolute
    right: 0
    top: 10.1rem
    width: 66.1rem
    height: 84.3rem
    background-position: center
    background-size: cover
    @media (max-width: 767px)
      top: 50rem
      width: 21.2rem
      height: 42.8rem
</style>
