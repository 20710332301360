export default {
  state: {
    isMobile: false,
    mobileMenuMode: 0,
  },
  getters: {
    getIsMobile: (state) => state.isMobile,
    getMenuMode: (state) => state.mobileMenuMode,
  },
  mutations: {
    setIsMobile: (state, mode) => {
      state.isMobile = mode
    },
    setMenuMode: (state, mode) => {
      state.mobileMenuMode = mode
    },
  },
}
