<template>
  <desktop-cabinet-layout v-loading="loading">
    <main-button
      slot="button"
      title="Очистить трек"
      :icon="true"
      theme="light"
      class="track-button"
      @button-clicked="closeTrack"
    />
    <div slot="aside" class="aside">
      <TruckCard :truck="activeTruck" :active="true" @set-status="closeTrack" />
      <div class="aside__date">
        <span class="aside__date-title">Дата трека:&nbsp;</span>
        <span class="aside__date-content">{{ trackDate }}</span>
      </div>
    </div>
    <div slot="main" class="map">
      <yandex-one-route v-if="pointList.length" :list="pointList" />
      <glonass-empty
        v-if="!pointList.length && !loading"
        title="Нет данных за указанную дату"
      />
    </div>
  </desktop-cabinet-layout>
</template>

<script>
import DesktopCabinetLayout from "@/layouts/DesktopCabinetLayout"
import trackMixin from "@/views/TrackPage/trackMixin"

export default {
  name: "DesktopTrackView",
  components: { DesktopCabinetLayout },
  mixins: [trackMixin],
}
</script>

<style lang="sass" scoped>
@import "@/assets/styles/spinner-mixin.sass"
@include light-spiner
@import "./date-styles"
.map
  position: relative
  height: 100%
.track-button
  margin-left: 4rem
  padding: 1.2rem 2.4rem
  font-size: 1.4rem
.aside
  min-height: 100%
  padding: 27px 43px 84px
</style>
