export const MAP_CENTER = [44.856214, 38.006471]

export const ymapSettings = {
  // eslint-disable-next-line no-undef
  apiKey: process.env.VUE_APP_YANDEX_MAPS,
  lang: "ru_RU",
  coordorder: "latlong",
  version: "2.1",
}

export const markerIcon = {
  layout: "default#imageWithContent",
  imageHref: "",
  imageOffset: [-49, -37],
  imageSize: [100, 30],
  contentLayout: '<div class="marker">$[properties.iconContent]</div>',
}

export const markerIconActive = {
  layout: "default#imageWithContent",
  imageHref: "",
  imageOffset: [-49, -37],
  imageSize: [100, 30],
  contentLayout:
    '<div class="marker mod-hover">$[properties.iconContent]</div>',
}

export const multiRouteOptions = {
  // задаем границы карты: чтобы автоматом помещался весь маршрут
  boundsAutoApply: true,
  // параметры линии маршрута: цвет и толщина
  routeActiveStrokeColor: "#45C89A",
  routeActiveStrokeWidth: 4,
  // при открытии балуна роут стаёт неактивным, поэтому дублируем параметры линии
  routeStrokeColor: "#45C89A",
  routeStrokeWidth: 4,
  // задаем всем точкам пути одинаковое отображение
  // eslint-disable-next-line no-undef
  wayPointIconImageHref: require("@/assets/img/map-point.svg"),
  wayPointIconLayout: "default#image",
  wayPointIconImageSize: [14, 14],
  wayPointIconImageOffset: [-7, -7],
}
