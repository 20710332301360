<template>
  <section class="flex-between-center menu">
    <div class="flex">
      <div class="menu__logo">Порт-Транзит</div>
      <div class="flex-v-center">
        <div
          v-for="(item, index) in content.menuItems"
          :key="index"
          class="menu__item"
          @click="goToSection(item.component)"
        >
          {{ item.label }}
        </div>
      </div>
    </div>
    <div class="flex">
      <a
        v-for="contact in content.contacts"
        :key="contact.text"
        :href="contact.href"
        class="menu__contact flex-v-center"
      >
        <div
          :style="{ 'background-image': 'url(' + imageUrl(contact.icon) + ')' }"
          class="menu__contact-icon"
        />
        <div class="menu__contact-text">{{ contact.text }}</div>
      </a>
      <main-button title="Войти" class="menu__button" v-on="$listeners" />
    </div>
  </section>
</template>

<script>
import menuMixin from "@/mixins/menuMixin"
export default {
  name: "FixedMenuDesktop",
  mixins: [menuMixin],
}
</script>

<style lang="sass" scoped>
.menu
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 10.3rem
  padding: 0 6rem
  z-index: 5
  @media (max-width: 1023px)
    display: none
  @media (min-width: 1440px) and (max-width: 1599px)
    padding: 0 3rem
  &__logo
    margin-right: 14rem
    font-size: 3.2rem
    line-height: 4.4rem
    font-weight: 500
    @media (min-width: 1440px) and (max-width: 1599px)
      margin-right: 8rem
    @media (min-width: 1024px) and (max-width: 1439px)
      margin-right: 4rem
  &__item
    margin-right: 4rem
    font-family: $secondaryFont
    font-size: 1.6rem
    line-height: 2.2rem
    cursor: pointer
    @media (min-width: 1024px) and (max-width: 1439px)
      margin-right: 2rem
    &:hover
      color: $textAccent
  &__contact
    margin-right: 4.2rem
    text-decoration: none
    @media (min-width: 1024px) and (max-width: 1439px)
      margin-right: 2rem
    &-icon
      width: 1.6rem
      height: 1.6rem
      background-position: center
      background-size: cover
    &-text
      margin-left: 0.8rem
      font-size: 1.6rem
      line-height: 2.2rem
      font-weight: 500
      color: $textWhite

  &__button
    width: 14rem
    height: 4.4rem
</style>
