import Vue from "vue"
import Vuex from "vuex"

import Offsets from "@/store/modules/offsets"
import User from "@/store/modules/user"
import Device from "@/store/modules/device"
import Errors from "@/store/modules/errors"

Vue.use(Vuex)

// Vue.config.productionTip = false

export default new Vuex.Store({
  modules: {
    Offsets,
    User,
    Device,
    Errors,
  },
})
