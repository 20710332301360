<template>
  <section class="menu">
    <div class="menu__header flex-between-center">
      <div class="menu__header-logo">Порт-Транзит</div>
      <div class="flex-v-center">
        <main-button
          title="Войти"
          class="menu__header-button"
          v-on="$listeners"
        />
        <div class="menu__header-hamburger flex-center" @click="showDropdown">
          <img
            v-if="isDropdownHidden"
            src="@/assets/img/hamburger.svg"
            alt="menu"
          />
          <img v-else src="@/assets/img/menu-close.svg" alt="menu" />
        </div>
      </div>
    </div>
    <div :class="['menu__dropdown', { hidden: isDropdownHidden }]">
      <div class="menu__dropdown-items">
        <div
          v-for="(item, index) in content.menuItems"
          :key="index"
          :class="['item', { active: item.component === visibleElement }]"
          @click="swipeToBlock(item.component)"
        >
          <div class="item__title">{{ item.label }}</div>
          <div class="item__arrow flex-center">
            <arrow-svg />
          </div>
        </div>
      </div>
      <div>
        <a
          v-for="contact in content.contacts"
          :key="contact.text"
          :href="contact.href"
          class="menu__contact flex-v-center"
        >
          <div
            :style="{
              'background-image': 'url(' + imageUrl(contact.icon) + ')',
            }"
            class="menu__contact-icon"
          />
          <div class="menu__contact-text">{{ contact.text }}</div>
        </a>
      </div>
    </div>
  </section>
</template>

<script>
import menuMixin from "@/mixins/menuMixin"
import ArrowSvg from "@/components/landing/menu/ArrowSvg"
import { mapGetters } from "vuex"
export default {
  name: "FixedMenuMobile",
  mixins: [menuMixin],
  components: { ArrowSvg },
  data() {
    return {
      isDropdownHidden: true,
      currentScroll: 0,
      visibleElement: "",
    }
  },
  computed: {
    ...mapGetters({
      offsets: "getOffsets",
    }),
  },
  mounted() {
    this.scrollHandler()
    document.addEventListener("scroll", this.scrollHandler)
  },
  beforeDestroy() {
    document.removeEventListener("scroll", this.scrollHandler)
  },

  methods: {
    showDropdown() {
      this.isDropdownHidden = !this.isDropdownHidden
      // запрет прокрутки фонового элемента на время открытия меню
      document.body.style.overflow = this.isDropdownHidden ? "auto" : "hidden"
    },
    showForm() {
      this.isDropdownHidden = true
      this.$emit("show-form")
    },
    swipeToBlock(block) {
      this.isDropdownHidden = true
      document.body.style.overflow = "auto"
      this.goToSection(block)
    },
    // получение текущего скролла документа при прокрутке
    scrollHandler() {
      this.visibleElement = ""
      const menu = this.content.menuItems

      this.currentScroll =
        window.pageYOffset || document.documentElement.scrollTop
      for (let i = 0; i < menu.length - 1; i++) {
        if (
          this.currentScroll > this.offsets[menu[i].component] &&
          this.currentScroll < this.offsets[menu[i + 1].component]
        ) {
          this.visibleElement = menu[i].component
          break
        }
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.menu
  @media (min-width: 1024px)
    display: none
  &__header
    position: fixed
    top: 0
    left: 0
    right: 0
    height: 6rem
    padding: 0 2rem
    background: rgba(8, 15, 17, 0.7)
    border-bottom: 1px solid #222C2D
    backdrop-filter: blur(10px)
    z-index: 2000
    &-logo
      font-size: 1.8rem
      line-height: 2.2rem
      font-weight: 500

    &-hamburger
      width: 3.2rem
      height: 3.2rem
      border: 1px solid $textAccent
      border-radius: 10px
      cursor: pointer
      img
        width: 1.6rem
        height: 1.6rem
        object-fit: cover

    &-button
      width: 11.3rem
      height: 3.2rem
      margin-right: 2rem

  &__dropdown
    position: fixed
    top: 0
    left: 0
    right: 0
    display: flex
    flex-direction: column
    justify-content: space-between
    height: 100vh
    height: -webkit-fill-available
    padding: 10.1rem 2rem 6rem
    background: $bgColor
    transition: transform .5s ease
    z-index: 1500
    &.hidden
      transform: translateY(-100%)

    .item
      display: flex
      justify-content: space-between
      margin-bottom: 1.6rem
      padding-bottom: 1.6rem
      border-bottom: 1px solid #3D575D
      &__title
        font-family: $secondaryFont
        font-size: 1.6rem
        line-height: 2.2rem
        color: $textSecondary
      &__arrow
        width: 3rem
        height: 3rem
        svg
          width: 1.6rem
          height: 1.6rem
          stroke: $textSecondary
      &.active
        .item__title
          color: $textAccent
        svg
          stroke: $textAccent

  &__contact
    margin-top: 2rem
    text-decoration: none
    &-icon
      width: 1.6rem
      height: 1.6rem
      background-position: center
      background-size: cover
    &-text
      margin-left: 1.2rem
      font-size: 1.6rem
      line-height: 2.2rem
      font-weight: 500
      color: $textWhite
</style>
