// светлое пятновнизу страницы
export const pageBlock = {
  image: "footer.png",
}

export const topMenu = {
  menuItems: [
    {
      label: "Услуги",
      component: "GlonassServices",
    },
    {
      label: "Цены",
      component: "GlonassTariffs",
    },
    {
      label: "Договор",
      component: "GlonassOferta",
    },
    {
      label: "Реквизиты",
      component: "FooterComponent",
    },
  ],
  contacts: [
    {
      icon: "phone.svg",
      iconLight: "phone-light.svg",
      text: "+7 938 403 74 04",
      href: "tel:+79384037404",
    },
    {
      icon: "email.svg",
      iconLight: "email-light.svg",
      text: "glonass-nvrsk@yandex.ru",
      href: "mailto: glonass-nvrsk@yandex.ru",
    },
  ],
  button: "Задать вопрос",
}

export const headerBlock = {
  imageDesktop: "header.png",
  imageMobile: "header-mobile.png",
  title: "Установка GPS/<br>ГЛОНАСС<br>оборудования",
  description:
    "Комплексная система управления и контроля автомобильного транспорта в режиме реального времени с установкой в Новороссийске и Краснодарском крае",
  options: [
    {
      icon: "truck.svg",
      text: "Неограниченное количество транспорта",
    },
    {
      icon: "map.svg",
      text: "Информация о местоположении онлайн",
    },
    {
      icon: "fuel.svg",
      text: "Cокращение затрат на топливо",
    },
  ],
}

export const servicesBlock = {
  title: "Услуги",
  tiles: [
    {
      type: "Пакет",
      title: "Стандартный",
      icon: "services1.svg",
      list: [
        "/ GPS трекер с сим-картой",
        "/ Установка оборудования в г. Новороссийске",
        "/ Личный кабинет с возможностью построения треков движения",
        "/ Ретрансляция данных для зерновых терминалов",
        "/ Контроль местоположения транспортного средства, хранение истории перемещения",
        "/ Персональный менеджер и служба технической поддержки",
      ],
      price: "9 000 ₽",
    },
    {
      type: "Пакет",
      title: "Контроль топлива",
      icon: "services2.svg",
      list: [
        "/ GPS трекер с сим-картой",
        "/ Стандартный комплект беспроводной ДУТ",
        "/ Выездная установка",
        "/ Личный кабинет в системе Wialoin",
        "/ Ретрансляция данных для зерновых терминалов",
        "/ Контроль местоположения транспортного средства, хранение истории перемещения",
        "/ Персональный менеджер и служба технической поддержки",
      ],
      price: "от 24 500 ₽ <sup>*</sup>",
      comment:
        "<sup>*</sup> большим автоколоннам скидка, выезды за пределы Новороссийского района оплачиваются отдельно. Дополнительный бак от&nbsp;14000 руб",
    },
  ],
  imageDesktop: "light-spot.png",
  imageMobile: "light-spot-mobile.png",
}
export const tariffsBlock = {
  title: "Цены",
  services: [
    {
      title: "Устройство ГЛОНАСС +установка+настройка",
      item: "1 авто",
      price: "9000",
    },
    {
      title: "Абонентское обслуживание",
      item: "1 месяц",
      price: "500",
    },
    {
      title: "Подключение к Wialon Hosting",
      item: "1 авто",
      price: "1000",
    },
    {
      title:
        "Абоненское обслуживание. Заблокированный пользователь.&nbsp;<span>*</span>",
      item: "1 месяц",
      price: "150",
    },
    {
      title:
        "Ретрансляция данных от стороннего провайдера&nbsp;<span>**</span>",
      item: "1 месяц",
      price: "500",
    },
    {
      title:
        "Переход на обслуживание от стороннего провайдера&nbsp;<span>***</span>",
      item: "1 авто",
      price: "4500",
    },
    {
      title: "Датчика уровня топлива + установка + тарировка",
      item: "1 бак",
      price: "14000",
    },
  ],
  comments: [
    "* Пользователь может подать заявку на&nbsp;блокировку устройства. Блокировка действует кратно месяцу.",
    "** Услуга по&nbsp;организации передачи данных указанным заказчиком на&nbsp;сервер мониторинга ООО «Порт транзит». Минимальный пакет услуги&nbsp;-&nbsp;6 месяцев.",
    "*** Только при&nbsp;условии, что&nbsp;оборудование, установленное на&nbsp;автомобиле заказчика, может быть использовано для&nbsp;подключения к&nbsp;серверу мониторинга Исполнителя и&nbsp;полностью исправно.",
  ],
}

export const ofertaBlock = {
  title: "Договор оферты",
  button: "Скачать",
  document: "/oferta.docx",
  image: "oferta.png",
  trash: "oferta-lines.svg",
}

// данные для самой формы
export const formData = {
  login: {
    title: "Вход и регистрация",
    description: "Менеджер свяжется с вами в ближайшее время!",
    inputs: {
      truck: {
        title: "Номер авто",
        placeholder: "Введите номер авто",
        errors: {
          format:
            "Формат номера должен быть “м213тт99” или “1212тт” или “е2233кк”",
          empty: "Поле обязательно для заполнения ",
        },
      },
      phone: {
        title: "Номер телефона",
        placeholder: "Введите номер телефона ",
        errors: {
          format: "Некорректный номер",
          empty: "Поле обязательно для заполнения ",
        },
      },
    },
    button: "Войти",
    checkbox: {
      text: "Я согласен с ",
      link: "политикой конфиденциальности",
      url: "/policy.pdf",
      error: "Наш юрист будет ругаться, если вы не примете :(",
    },
  },
  confirm: {
    codeLength: 6,
    title: "Подтверждение",
    description: "Мы отправили код на номер",
    back: "Изменить номер ",
    inputs: {
      code: {
        title: "Пароль",
        placeholder: "Код подтверждения",
        errors: {
          format: "Неверный формат кода",
          empty: "Поле обязательно для заполнения",
        },
      },
    },
    button: "Проверить код",
    timer: {
      wait: "Отправть код повторно через",
      resend: "Запросить новый код",
    },
  },
}

export const footerBlock = {
  company: {
    name: "ООО «Порт-транзит»",
    inn: "ИНН 2315182118",
    address: "Юридический адрес: 353960, Новороссийск, ул. Ленина, д. 7м",
  },
  copyright: "ООО Порт-Транзит ©",
}

export const trackModal = {
  title: "Создайте трек для отслеживания машины",
  inputs: {
    plate: {
      title: "Машина",
      placeholder: "Выберите машину",
      errors: {
        empty: "Необходимо указать машину",
        absent: "Нет такого номера в списке",
        blocked: "Невозможно построить трек для заблокированной машины",
      },
    },
    date: {
      title: "Дата трека",
      placeholder: "Выберите дату трека",
      errors: {
        empty: "Необходимо указать дату трека",
      },
    },
  },
  button: "Построить",
}

export const exitModal = {
  title: "Вы уверены, что хотите выйти? ",
  description: "Вы не сможете отследить трек машины",
  decline: "Отменить",
  accept: "Выйти",
}

export const connectModal = {
  title: "Подключить машину к ГЛОНАСС? ",
  description: "Вы сможете отследить трек машины",
  decline: "Отменить",
  accept: "Подключить",
}

export const disconnectModal = {
  title: "Вы уверены что хотите заблокировать машину?",
  description: "Вы не сможете отследить трек машины",
  decline: "Отменить",
  accept: "Заблокировать",
}

export const billModal = {
  title: "Получить счет",
  description:
    "Для оформления счета свяжитесь с нашими менеджерами по телефону или отправьте запрос на электронную почту",
  accept: "Понятно",
}

export const errorModal = {
  title: "Ошибка!",
  description: "Произошёл технический сбой. Попробуйте повторить позднее",
  accept: "Понятно",
}

export const emptyBlock = {
  title: "У вас нет подключенных автомобилей",
}
