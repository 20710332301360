import { topMenu } from "@/content/data"
import { imageUrl } from "@/helpers/imageLoading"
import MainButton from "@/components/common/Form/MainButton"

export default {
  components: { MainButton },
  data() {
    return {
      content: topMenu,
      imageUrl,
    }
  },
  methods: {
    goToSection(section) {
      window.scrollTo({
        top: this.$store.getters.getOffsets[section] + 1,
        behavior: "smooth",
      })
    },
  },
}
