<template>
  <div class="empty flex-center">
    <div class="empty__icon">
      <img src="@/assets/img/no-truck.svg" alt="empty" />
    </div>
    <div class="empty__text">{{ title }}</div>
  </div>
</template>

<script>
export default {
  name: "GlonassEmpty",
  props: {
    title: {
      type: String,
      default: "",
    },
  },
}
</script>

<style lang="sass" scoped>
.empty
  height: 100%
  flex-direction: column
  &__icon
    display: flex
    justify-content: center
    align-items: center
    width: 82px
    height: 82px
    margin-bottom: 16px
    background: $scrollbarGray
    border-radius: 50%

  &__text
    font-size: 1.6rem
    line-height: 2rem
    color: $tertiaryBlack
</style>
