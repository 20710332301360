import Vue from "vue"

import ElementUI from "element-ui"
import locale from "element-ui/lib/locale/lang/ru-RU"
import "element-ui/lib/theme-chalk/index.css"
Vue.use(ElementUI, { locale })

import VueSecureHTML from "vue-html-secure"
Vue.use(VueSecureHTML)

import VueTheMask from "vue-the-mask"
Vue.use(VueTheMask)

import "./assets/styles/main.sass"
import App from "./App.vue"
import store from "./store"
import router from "./router"

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app")
