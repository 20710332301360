export default {
  state: {
    isError: false,
  },
  getters: {
    getIsError: (state) => state.isError,
  },
  mutations: {
    setIsError: (state, mode) => {
      state.isError = mode
    },
  },
}
