<template>
  <modal-wrapper :visible="isVisible">
    <template slot="content">
      <div class="modal-light-title mod-error">{{ content.title }}</div>
      <div class="modal-light-description">{{ content.description }}</div>
      <div class="flex-center">
        <main-button
          :title="content.accept"
          theme="light"
          class="button"
          @button-clicked="isVisible = false"
        />
      </div>
    </template>
  </modal-wrapper>
</template>

<script>
import ModalWrapper from "@/components/common/Modals/ModalWrapper"
import MainButton from "@/components/common/Form/MainButton"
import { errorModal } from "@/content/data"

export default {
  name: "ModalError",
  components: {
    ModalWrapper,
    MainButton,
  },
  data() {
    return {
      content: errorModal,
    }
  },
  computed: {
    isVisible: {
      get() {
        return this.$store.getters.getIsError
      },
      set(value) {
        this.$store.commit("setIsError", value)
      },
    },
  },
}
</script>

<style lang="sass" scoped>
.button
  margin-top: 5rem
  width: 100%
  height: 48px
</style>
