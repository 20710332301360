<template>
  <modal-wrapper :visible="visible" size="small" v-on="$listeners">
    <template slot="content">
      <div class="modal-light-title">{{ content.title }}</div>
      <div class="modal-light-description">{{ content.description }}</div>
      <div class="flex-between-center">
        <main-button
          :title="content.decline"
          theme="passive"
          class="button"
          @button-clicked="$emit('close')"
        />
        <main-button
          :title="content.accept"
          theme="light"
          class="button"
          @button-clicked="$emit('confirm')"
        />
      </div>
    </template>
  </modal-wrapper>
</template>

<script>
import ModalWrapper from "@/components/common/Modals/ModalWrapper"
import MainButton from "@/components/common/Form/MainButton"

export default {
  name: "ModalSmall",
  components: {
    ModalWrapper,
    MainButton,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    content: {
      type: Object,
      default: () => ({}),
    },
  },
}
</script>

<style lang="sass" scoped>
.flex-between-center
  margin-top: 2.4rem
.button
  width: 172px
  height: 48px
  @media (max-width: 1023px)
    width: 49%
    height: 4.4rem
    max-width: 17.2rem
</style>
