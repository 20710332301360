<template>
  <section ref="anchor" class="tariffs">
    <div class="font1-56-62 tariffs__title">{{ content.title }}</div>
    <div
      v-for="(service, index) in content.services"
      :key="index"
      class="tariffs__service flex-between-center"
    >
      <div
        v-html-safe="service.title"
        class="font1-24-30 tariffs__service-title"
      />
      <div class="flex">
        <div class="font2-18-24 tariffs__service-item">{{ service.item }}</div>
        <div class="font1-24-30 tariffs__service-price">
          {{ priceFormat(service.price, 0) }}
        </div>
      </div>
    </div>
    <div class="tariffs__comments">
      <div
        v-for="(comment, index2) in content.comments"
        :key="index2"
        v-html-safe="comment"
        class="font2-16-22 tariffs__comments-item"
      />
    </div>
  </section>
</template>

<script>
import { tariffsBlock } from "@/content/data"
import { priceFormat } from "@/helpers/moneyFormat"
import { imageUrl } from "@/helpers/imageLoading"
import offsetMixin from "@/mixins/offsetMixin"

export default {
  name: "GlonassTariffs",
  mixins: [offsetMixin],
  data() {
    return {
      content: tariffsBlock,
      priceFormat,
      imageUrl,
    }
  },
}
</script>

<style lang="sass" scoped>
.tariffs
  padding: 0 $desktopHorizontalPaddings $desktopVerticalPaddings
  @media (max-width: 1023px)
    padding: 0 $mobileHorizontalPaddings $mobileVerticalPaddings

  &__title
    margin-bottom: 2.8rem
    @media (max-width: 1023px)
      margin-bottom: 2.3rem

  &__service
    padding: 3.2rem 0
    border-bottom: 1px solid #565656
    @media (max-width: 1023px)
      padding: 2rem 0
    &-title
      width: 75%
    &-item
      color: $textGrayLight
    &-price
      text-align: end
      color: $textAccent
      letter-spacing: -0.005em
      @media (max-width: 1023px)
        margin-bottom: 2px
    .flex
      flex-shrink: 0
      justify-content: space-between
      width: 25%
      @media (max-width: 1023px)
        flex-direction: column-reverse
        align-items: flex-end

  &__comments
    width: 68%
    min-width: 1003px
    margin-top: 3rem
    color: $commentColor
    @media (max-width: 1279px)
      width: 100%
      min-width: unset
    &-item
      margin-top: 1rem
</style>
