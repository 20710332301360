<template>
  <div class="wrapper" v-loading="isMapLoading">
    <yandex-map
      :settings="ymapSettings"
      :bounds="bounds"
      :coords="center"
      :controls="controls"
      @map-was-initialized="mapLoaded"
      @click="$emit('clicked', '')"
    >
      <ymap-marker
        v-for="(truck, index) in currentPoints"
        :key="index"
        marker-type="placemark"
        :marker-id="index"
        :coords="truck.coords"
        :icon="{ ...markerType(truck.id), content: truck.id }"
        @mouseenter="$emit('hovered', truck.id)"
        @mouseleave="$emit('unhovered')"
        @click="$emit('clicked', truck.id)"
      />
    </yandex-map>
  </div>
</template>

<script>
import {
  ymapSettings,
  markerIcon,
  markerIconActive,
  MAP_CENTER,
} from "@/components/YandexMap/constants"
import { yandexMap, ymapMarker } from "vue-yandex-maps"
export default {
  name: "YandexMapComponent",
  components: { yandexMap, ymapMarker },
  props: {
    points: {
      type: Array,
      default: () => [],
    },
    active: {
      type: String,
      default: "",
    },
    hovered: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      controls: ["zoomControl"],
      map: null,
      isMapLoading: true,
      ymapSettings,
      markerIcon,
      markerIconActive,
      currentPoints: [],
    }
  },
  computed: {
    center() {
      if (!this.currentPoints.length) return MAP_CENTER
      return this.currentPoints.length === 1 ? this.currentPoints[0].coords : []
    },
    bounds() {
      // if (!this.currentPoints.length) return MAP_BOUNDS
      if (this.currentPoints.length === 1) {
        // если только одна машина, то хардкодим размеры карты относительно машины
        return [
          [
            this.currentPoints[0].coords[0] - 4,
            this.currentPoints[0].coords[1] - 4,
          ],
          [
            this.currentPoints[0].coords[0] + 4,
            this.currentPoints[0].coords[1] + 4,
          ],
        ]
      }
      // иначе вычисляем границы карты
      let latitudes = this.currentPoints.map((item) => item.coords[0])
      let longitudes = this.currentPoints.map((item) => item.coords[1])
      return [
        [Math.min(...latitudes), Math.min(...longitudes)],
        [Math.max(...latitudes), Math.max(...longitudes)],
      ]
    },
  },
  watch: {
    points() {
      this.isMapLoading = true
      this.currentPoints = []
      this.$nextTick(() => {
        this.currentPoints = this.points
      })
      this.isMapLoading = false
    },
  },
  created() {
    this.currentPoints = this.points
  },
  methods: {
    markerType(id) {
      return id === this.active || id === this.hovered
        ? markerIconActive
        : markerIcon
    },
    mapLoaded(e) {
      // сохраняем инстанс карты (вдруг кому пригодится)
      e.options.set("suppressMapOpenBlock", true)
      // инстанс карты будет здесь
      this.map = e
      this.isMapLoading = false
    },
  },
}
</script>

<style lang="sass" scoped>
@import "@/assets/styles/spinner-mixin.sass"
@include light-spiner
.wrapper,
.ymap-container
  height: 100%

::v-deep .marker
  position: relative
  width: 100px
  padding: 6px 0
  font-family: $secondaryFont
  font-weight: 700
  font-size: 14px
  line-height: 18px
  border-radius: 6px
  white-space: nowrap
  background: $special
  color: #ffffff
  box-shadow: 0 7px 10px rgba(0, 0, 0, 0.12)
  &::after
    content: ""
    position: absolute
    bottom: -14px
    left: 50%
    transform: translateX(-50%)
    border: 6px solid transparent
    border-top: 10px solid $special
  &.mod-hover
    background: $specialHover
    z-index: 10000
    &::after
      border-top: 10px solid $specialHover

::v-deep .no-hint
  display: none
</style>
