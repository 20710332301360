<template>
  <section>
    <div
      :class="['card', { active: active }, { hovered: hovered }]"
      @click.stop="$emit('clicked')"
      @mouseenter="$emit('hovered')"
      @mouseleave="$emit('unhovered')"
      v-loading="loading"
    >
      <div class="flex-between-center">
        <div class="flex-v-center">
          <div class="card__icon" />
          <div class="card__plate">{{ truck.auto_num }}</div>
        </div>
        <div class="flex-v-center">
          <div class="card__account">На счету</div>
          <div class="card__amount">{{ priceFormat(truck.balance, 2) }}</div>
        </div>
      </div>
      <div class="card__handler">
        <div :class="['card__handler-state', { passive: isBlocked }]">
          {{ stateText }}
        </div>
        <div class="flex">
          <el-popover
            placement="bottom"
            trigger="hover"
            content="Выставить счет"
            popper-class="glonass-card-tooltip"
          >
            <div
              slot="reference"
              class="card__handler-button bill flex-center"
              @click="showBillModal = true"
            >
              <svg>
                <use xlink:href="#bill" />
              </svg>
            </div>
          </el-popover>
          <el-popover
            placement="bottom"
            trigger="hover"
            :content="connectionPopoverTitle"
            popper-class="glonass-card-tooltip"
          >
            <div
              slot="reference"
              :class="[
                'card__handler-button unmount flex-center',
                { passive: isBlocked },
              ]"
              @click="showConnectionModal = true"
            >
              <svg>
                <use xlink:href="#unmount" />
              </svg>
            </div>
          </el-popover>
        </div>
      </div>
    </div>
    <modal-bill :visible="showBillModal" @close="showBillModal = false" />
    <modal-small
      :visible="showConnectionModal"
      :content="connectionModalContent"
      @close="showConnectionModal = false"
      @confirm="setConnection"
    />
    <ButtonSvgSprite />
  </section>
</template>

<script>
import ButtonSvgSprite from "@/components/ButtonsSvgSprite"
import ModalSmall from "@/components/common/Modals/ModalSmall"
import ModalBill from "@/components/common/Modals/ModalBill"
import { priceFormat } from "@/helpers/moneyFormat"
import { connectModal, disconnectModal } from "@/content/data"

import Api from "@/api"

export default {
  name: "TruckCard",
  components: { ButtonSvgSprite, ModalSmall, ModalBill },
  props: {
    truck: {
      type: Object,
      default: () => ({}),
    },
    active: {
      type: Boolean,
      default: false,
    },
    hovered: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      showBillModal: false,
      showConnectionModal: false,
      priceFormat,
    }
  },
  computed: {
    isBlocked() {
      return this.truck.status === "blocked"
    },
    stateText() {
      return this.isBlocked ? "Заблокирован" : "Подключен"
    },
    connectionModalContent() {
      return this.isBlocked ? connectModal : disconnectModal
    },
    connectionPopoverTitle() {
      return this.isBlocked ? "Разблокировать" : "Заблокировать"
    },
  },
  methods: {
    async setConnection() {
      this.loading = true
      const request = this.isBlocked ? "unblockTruck" : "blockTruck"
      try {
        await Api[request](this.truck.auto_num)
        this.$emit("set-status")
      } catch (e) {
        this.$store.commit("setIsError", true)
      } finally {
        setTimeout(() => (this.loading = false), 1000)
        this.showConnectionModal = false
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.card
  margin-bottom: 16px
  padding: 14px 16px
  background: $textWhite
  border: 1px solid $textWhite
  box-shadow: 0 0 10px rgba(142, 142, 142, 0.06)
  border-radius: 12px
  transition: border 0.3s ease
  cursor: pointer
  &.active, &.hovered
    border: 1px solid $special

  &__icon
    width: 16px
    height: 16px
    margin-right: 8px
    background-image: url("~@/assets/img/truck-black.svg")
    background-size: cover
  &__plate
    font-size: 20px
    font-weight: 500
    line-height: 24px
    letter-spacing: 0.045em
  &__account
    margin-right: 8px
    font-family: $secondaryFont
    font-size: 12px
    line-height: 16px
    color: $textGray
  &__amount
    font-size: 14px
    font-weight: 500
    line-height: 16px

  &__handler
    display: flex
    justify-content: space-between
    align-items: flex-end
    margin-top: 6px
    &-state
      height: 20px
      padding: 0 8px
      font-family: $secondaryFont
      font-size: 12px
      font-weight: 700
      line-height: 20px
      background: #F0FBF7
      color: $special
      border-radius: 8px
      &.passive
        background: $textSecondary
        color: $textGray

    &-button
      width: 26px
      height: 26px
      margin-left: 8px
      border-radius: 8px
      border-width: 1px
      border-style: solid
      cursor: pointer
      transition: 0.3s ease
      svg
        width: 14px
        height: 14px
        fill: none

      &.bill
        border-color: #C1DAF2
        svg
          stroke: #4D8AC4
        &:hover
          border-color: #C1DAF2
          background: #4D8AC4
          svg
            stroke: $textWhite

      &.unmount
        border-color: #FDCDD6
        svg
          stroke: $errorColor
        &:hover
          border-color: $errorColor
          background: $errorColor
          svg
            stroke: $textWhite

        &.passive
          border-color: #B9EAD8
          svg
            stroke: #00AE88
          &:hover
            border-color: $special
            background: $special
            svg
              stroke: $textWhite
</style>
