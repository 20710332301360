// eslint-disable-next-line no-undef
const userKey = process.env.VUE_APP_LS_KEY + "user"
// eslint-disable-next-line no-undef
const trucksKey = process.env.VUE_APP_LS_KEY + "trucks"

export const getUser = () => JSON.parse(localStorage.getItem(userKey))
export const setUser = (user) =>
  localStorage.setItem(userKey, JSON.stringify(user))
export const removeUser = () => localStorage.removeItem(userKey)

export const getTrucks = () => JSON.parse(localStorage.getItem(trucksKey))
export const setTrucks = (trucks) =>
  localStorage.setItem(trucksKey, JSON.stringify(trucks))
export const removeTrucks = () => localStorage.removeItem(trucksKey)
