import Vue from "vue"
import VueRouter from "vue-router"
import routes from "./routes"

import store from "@/store"

Vue.use(VueRouter)

const router = new VueRouter({
  mode: "history",
  routes,
})

router.beforeEach((to, from, next) => {
  if (to.name === "home") {
    store.getters.getUser ? next({ name: "glonass" }) : next()
  } else {
    store.getters.getUser ? next() : next({ name: "home" })
  }
})

export default router
