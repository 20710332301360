import MainButton from "@/components/common/Form/MainButton"
import TruckCard from "@/components/TruckCard/TruckCard"
import GlonassEmpty from "@/components/glonass/GlonassEmpty"
import { getTrucks, removeTrucks } from "@/helpers/storage"
import { dateRuFromQuery } from "@/helpers/date"
import YandexOneRoute from "@/components/YandexMap/YandexOneRoute"
import Api from "@/api"
export default {
  components: { MainButton, TruckCard, YandexOneRoute, GlonassEmpty },
  data() {
    return {
      activeTruck: null,
      map: null,
      pointList: [],
      loading: false,
    }
  },
  computed: {
    trackDate() {
      return dateRuFromQuery(this.$route.query.date)
    },
  },
  async created() {
    this.loading = true
    const trucks = getTrucks()
    this.activeTruck = trucks.find(
      (item) => item.auto_num === this.$route.query.num_auto
    )
    try {
      const { data } = await Api.track(this.$route.query)
      this.pointList = data.data.coords
    } catch (e) {
      this.$store.commit("setIsError", true)
    } finally {
      this.loading = false
    }
  },
  methods: {
    closeTrack() {
      removeTrucks()
      this.$router.push({ name: "glonass" })
    },
  },
}
