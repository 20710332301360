<template>
  <div>
    <div class="content__title">{{ content.title }}</div>
    <div class="font2-16-22 content__description">
      {{ content.description }}
    </div>
    <form v-loading="loading" name="contacts" class="form-login">
      <double-input
        :title="content.inputs.truck.title"
        :placeholder="content.inputs.truck.placeholder"
        :has-error="hasTruckError"
        :error-text="truckError"
        theme="dark"
        class="form-login__input"
        @input-focus="hasTruckError = false"
        @input-changed="getField('num_auto', $event)"
      />
      <double-input
        :title="content.inputs.phone.title"
        :placeholder="content.inputs.phone.placeholder"
        :has-error="hasPhoneError"
        :error-text="phoneError"
        theme="dark"
        v-mask="'+7 (###) ###-##-##'"
        class="form-login__input"
        @input-focus="hasPhoneError = false"
        @input-changed="getField('phone_number', $event)"
      />
      <el-checkbox
        v-model="isAgree"
        :class="['form-login__checkbox', { error: hasAgreeError }]"
        @change="hasAgreeError = false"
      >
        {{ content.checkbox.text }}
        <a :href="content.checkbox.url" target="_blank">
          {{ content.checkbox.link }}
        </a>
      </el-checkbox>
      <transition>
        <div v-if="hasAgreeError" class="checkbox__error">
          <p class="checkbox__error-text">{{ agreeError }}</p>
        </div>
      </transition>
      <main-button
        :title="content.button"
        class="form-login__button"
        @button-clicked="startRequest"
      />
    </form>
  </div>
</template>

<script>
import DoubleInput from "@/components/common/Form/DoubleInput"
import MainButton from "@/components/common/Form/MainButton"
import { numAutoValidator } from "@/helpers/validation"
import { formData } from "@/content/data"
import Api from "@/api"
export default {
  name: "AuthForm",
  components: { DoubleInput, MainButton },
  data() {
    return {
      content: formData.login,
      form: {
        num_auto: "",
        phone_number: "",
      },
      hasTruckError: false,
      truckError: "",
      hasPhoneError: false,
      phoneError: "",
      hasAgreeError: false,
      agreeError: "",
      isAgree: true,
      loading: false,
    }
  },
  computed: {
    hasValidationErrors() {
      return this.hasTruckError || this.hasPhoneError || this.hasAgreeError
    },
    formattedPhone() {
      return this.form.phone_number.replace(/[\s()-]/g, "")
    },
  },
  methods: {
    getField(field, value) {
      this.form[field] = value
    },
    validateData() {
      if (numAutoValidator(this.form.num_auto)) {
        this.hasTruckError = true
        this.truckError = this.form.num_auto.length
          ? this.content.inputs.truck.errors.format
          : this.content.inputs.truck.errors.empty
      }
      if (this.form.phone_number.length !== 18) {
        this.hasPhoneError = true
        this.phoneError = this.form.phone_number.length
          ? this.content.inputs.phone.errors.format
          : this.content.inputs.phone.errors.empty
      }
      if (!this.isAgree) {
        this.hasAgreeError = true
        this.agreeError = this.content.checkbox.error
      }
    },

    async startRequest() {
      this.hasTruckError = false
      this.hasPhoneError = false
      this.hasAgreeError = false
      this.validateData()

      if (!this.hasValidationErrors) {
        let formData = new FormData()
        formData.append("num_auto", this.form.num_auto)
        formData.append("phone_number", this.formattedPhone)
        this.loading = true
        try {
          const { data } = await Api.login(formData)
          //TODO Убрать передачу кода перед переходом в прод
          this.$emit("request-successful", {
            timerDelay: data.data.can_request_sms_again_after_seconds,
            phone_number: this.formattedPhone,
            code: data.data.code,
            num_auto: this.form.num_auto,
          })
        } catch (e) {
          this.hasPhoneError = true
          this.phoneError = e.response.data.error
          this.hasTruckError = true
          this.truckError = e.response.data.error
        } finally {
          this.loading = false
        }
      }
    },
  },
}
</script>

<style lang="sass" scoped>
@import "./auth-styles"

.form-login__checkbox
  margin-bottom: 3rem

.content__description
  margin-bottom: 2.4rem

::v-deep .el-checkbox.error .el-checkbox__inner
  border: 1px solid $errorColor

::v-deep .el-checkbox__inner
  background: transparent

::v-deep .el-checkbox__input.is-checked .el-checkbox__inner
  background-color: $textAccent
  border-color: $textAccent

::v-deep .el-checkbox__inner
  width: 24px
  height: 24px
  border: none
  border-radius: 4px
  @media (max-width: 1023px)
    width: 16px
    height: 16px
  &::after
    top: 3px
    left: 9px
    width: 6px
    height: 12px
    border-color: #1E2F30
    @media (max-width: 1023px)
      left: 6px
      width: 3px
      height: 8px

::v-deep .el-checkbox
  color: #1E2F30
  a
    color: #1E2F30

::v-deep .el-checkbox__label
  font-family: $secondaryFont
  font-weight: 400
  font-size: 1.2rem
  line-height: 1.6rem
  color: $textGray
  a
    color: $textGray

::v-deep .el-checkbox__input.is-checked+.el-checkbox__label
  color: $textGray

.checkbox__error
  display: flex
  align-items: center
  height: 3rem
  margin-top: -3rem
  font-family: $secondaryFont
  font-size: 1.2rem
  line-height: 1.6rem
  color: $errorColor
  @media (max-width: 400px)
    font-size: 1.1rem
  &-image
    margin-top: 2px
    margin-right: 2px
</style>
