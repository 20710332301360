<template>
  <div>
    <div ref="autocomplete_wrapper" class="autocomplete" @keyup.tab="start">
      <div class="autocomplete__content" @click="start">
        <div :class="['autocomplete__content-prompt', selectState]">
          {{ currentTitle }}
        </div>
        <el-autocomplete
          v-model="result"
          ref="autocomplete"
          :class="['autocomplete__content-select block-input', selectState]"
          :fetch-suggestions="querySearch"
          @focus="isEditing = true"
          @blur="isEditing = false"
          @change="onChange"
          @select="selectValue"
        />
      </div>
      <div
        :class="['autocomplete__icon', { open: isEditing }]"
        @click.stop="start"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "AutocompleteInput",
  props: {
    title: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    options: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      result: "",
      selectState: "",
      isEditing: false,
    }
  },

  computed: {
    currentTitle() {
      return this.result.length ? this.title : this.placeholder
    },
  },

  methods: {
    querySearch(queryString, cb) {
      let results = []
      if (queryString) {
        results = this.options.filter(
          (item) =>
            item.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0
        )
      } else {
        results = this.options
      }
      cb(results)
    },

    selectValue() {
      this.selectState = "filled"
      this.isEditing = false
      this.$emit("select", this.result)
    },
    start() {
      this.$refs.autocomplete.focus()
      this.selectState = "filled"
      this.$emit("input-focus")
    },
    onChange() {
      this.selectState = this.result.length ? "filled" : ""
      this.isEditing = false
      this.$emit("select", this.result)
    },
  },
}
</script>

<style lang="sass" scoped>
.autocomplete
  position: relative
  display: flex
  justify-content: space-between
  align-items: center
  width: 100%
  height: 50px
  border-radius: 12px
  background: $textSecondary

  &__content
    flex: 1
    display: flex
    flex-direction: column
    justify-content: center
    padding: 8px 10px 8px 16px
    border-radius: 12px
    cursor: pointer

    &-select
      width: 100%
      height: 0
      padding: 0
      border-width: 0
      outline: none
      font-size: 0
      background: $textSecondary
      transition: height 0.3s ease
      color: $primaryBlack
      position: relative
      ::v-deep .el-input__inner
        height: 0
        padding-left: 1px
        padding-right: 0
        border-color: transparent !important
        background-color: transparent
        text-overflow: ellipsis
        position: absolute
        top: -14px

      ::v-deep .el-input.is-focus .el-input__inner
        border-color: transparent !important
        background-color: transparent

      &.filled
        height: 18px
        font-size: 14px
        ::v-deep .el-input__inner
          height: 18px

    &-prompt
      padding-left: 1px
      font-size: 14px
      color: $textGray
      transition: all 0.3s ease

      &.filled
        font-size: 12px
        line-height: 16px

.autocomplete__icon
  position: absolute
  top: 0
  right: 17px
  width: 50px
  height: 50px
  background-position: center
  background-repeat: no-repeat
  transition: transform 0.3s ease
  cursor: pointer
  background-image: url("~@/assets/img/angle-down-icon.svg")

  &.open
    transform: rotate(180deg)
</style>
