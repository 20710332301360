<template>
  <form class="form">
    <div v-html-safe="content.title" class="modal-light-title form__title" />
    <autocomplete-input
      :title="content.inputs.plate.title"
      :placeholder="content.inputs.plate.placeholder"
      :options="plateOptions"
      :class="['form__item', { error: hasPlateError }]"
      @input-focus="hasPlateError = false"
      @select="setField('num_auto', $event)"
    />
    <transition>
      <div v-if="hasPlateError" class="form__error">
        <div class="form__error-image">
          <img src="@/assets/img/new-error.svg" alt="phone" />
        </div>
        <p class="form__error-text">{{ plateError }}</p>
      </div>
    </transition>

    <DatePicker
      :title="content.inputs.date.title"
      :placeholder="content.inputs.date.placeholder"
      :class="['form__item', { error: hasDateError }]"
      @start="hasDateError = false"
      @input="setField('date', $event)"
    />
    <transition>
      <div v-if="hasDateError" class="form__error">
        <div class="form__error-image">
          <img src="@/assets/img/new-error.svg" alt="phone" />
        </div>
        <p class="form__error-text">{{ dateError }}</p>
      </div>
    </transition>

    <main-button
      :title="content.button"
      theme="light"
      class="form__button"
      @button-clicked="validateForm"
    />
  </form>
</template>

<script>
import { trackModal } from "@/content/data"
import MainButton from "@/components/common/Form/MainButton"
import AutocompleteInput from "@/components/common/Form/Auth/AutocompleteInput"
import DatePicker from "@/components/common/Form/DatePicker"

export default {
  name: "TrackForm",
  components: { MainButton, AutocompleteInput, DatePicker },
  props: {
    plateOptions: {
      type: Array,
      default: () => [],
    },
    truckList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      content: trackModal,
      form: {
        num_auto: "",
        date: "",
      },
      hasPlateError: false,
      plateError: "",
      hasDateError: false,
      dateError: "",
    }
  },
  computed: {
    hasErrors() {
      return this.hasPlateError || this.hasDateError
    },
  },
  methods: {
    setField(field, value) {
      this.form[field] = value
    },
    validateForm() {
      if (!this.form.num_auto.length) {
        this.hasPlateError = true
        this.plateError = this.content.inputs.plate.errors.empty
      } else {
        if (
          !this.plateOptions.find((item) => item.value === this.form.num_auto)
        ) {
          this.hasPlateError = true
          this.plateError = this.content.inputs.plate.errors.absent
        } else {
          // нельзя построить трек по заблокированной машине
          let truck = this.truckList.find(
            (item) => item.auto_num === this.form.num_auto
          )
          if (truck.status === "blocked") {
            this.hasPlateError = true
            this.plateError = this.content.inputs.plate.errors.blocked
          }
        }
      }

      if (!this.form.date.length) {
        this.hasDateError = true
        this.dateError = this.content.inputs.date.errors.empty
      }

      if (!this.hasErrors) {
        this.$router.push({
          name: "track",
          query: this.form,
        })
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.form
  color: $primaryBlack

  &__title
    margin-bottom: 2.4rem

  &__item
    margin-bottom: 2.4rem
    border-radius: 12px
    border: 1px solid transparent
    @media (max-width: 1023px)
      margin-bottom: 2.8rem
    &.error
      border: 1px solid $errorColor

  &__button
    width: 100%
    height: 48px

  &__error
    display: flex
    align-items: center
    height: 2.4rem
    margin-top: -2.4rem
    font-family: $secondaryFont
    font-size: 1.2rem
    line-height: 1.6rem
    color: $errorColor
    &-image
      margin-top: 2px
      margin-right: 4px
</style>
