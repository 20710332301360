export const numAutoValidator = (plate) => {
  if (plate && plate.length) {
    let numAuto = plate.toLowerCase()
    return (
      !/^[a-zA-zА-Яа-я]{2}[0-9]{6,7}$/.test(numAuto) &&
      !/^[a-zA-zА-Яа-я]{1}[0-9]{3}[a-zA-zА-Яа-я]{2}[0-9]{2,3}$/.test(numAuto) &&
      !/^[0-9]{4}[a-zA-zА-Яа-я]{3}$/.test(numAuto) &&
      !/^[a-zA-zА-Яа-я]{1}[0-9]{4}[a-zA-zА-Яа-я]{2}$/.test(numAuto)
    )
  }
  return true
}
