<template>
  <section ref="anchor" class="oferta">
    <div class="oferta__title font1-48-56">{{ content.title }}</div>
    <main-button
      :title="content.button"
      class="oferta__button"
      @button-clicked="downloadDocument"
    />
    <div
      :style="{ 'background-image': 'url(' + imageUrl(content.image) + ')' }"
      class="oferta__image"
    />
    <div
      :style="{ 'background-image': 'url(' + imageUrl(content.trash) + ')' }"
      class="oferta__trash"
    />
  </section>
</template>

<script>
import { ofertaBlock } from "@/content/data"
import { imageUrl } from "@/helpers/imageLoading"
import offsetMixin from "@/mixins/offsetMixin"
import MainButton from "@/components/common/Form/MainButton"
export default {
  name: "GlonassOferta",
  components: { MainButton },
  mixins: [offsetMixin],
  data() {
    return {
      content: ofertaBlock,
      imageUrl,
    }
  },
  methods: {
    downloadDocument() {
      const linkElement = document.createElement("a")

      linkElement.href = this.content.document
      linkElement.setAttribute("target", "blank")
      linkElement.setAttribute("download", this.content.title)
      document.body.appendChild(linkElement)
      linkElement.click()
      linkElement.remove()
    },
  },
}
</script>

<style lang="sass" scoped>
.oferta
  position: relative
  margin: 0 $desktopHorizontalPaddings $desktopVerticalPaddings
  background: linear-gradient(95.48deg, #0D1A1B 8.19%, #0E1A1A 39.5%)
  border: 1px solid rgba(211, 255, 233, 0.06)
  border-radius: 40px
  overflow: hidden
  z-index: 1
  @media (max-width: 1023px)
    margin: 0 $mobileHorizontalPaddings $mobileVerticalPaddings
    flex-direction: column

  &__title
    position: relative
    margin: 4.8rem
    z-index: 2
    letter-spacing: -0.005em
    @media (max-width: 1023px)
      margin: 3.2rem 3.2rem 3.8rem
  &__button
    position: relative
    width: 17.8rem
    height: 4.4rem
    margin: 0 0 4.8rem 4.8rem
    z-index: 2
    @media (max-width: 1023px)
      margin: 0 0 3.2rem 3.2rem
  &__image
    position: absolute
    right: 0
    top: 0
    bottom: 0
    width: 78.4rem
    background-size: contain
    background-repeat: no-repeat
    @media (max-width: 1023px)
      left: 50%
      right: auto
      width: 58.8rem
  &__trash
    position: absolute
    bottom: 0
    left: 50.3rem
    width: 97.7rem
    height: 25rem
    background-size: cover
    background-repeat: no-repeat
    @media (max-width: 1023px)
      left: 19.7rem
      width: 18.7rem
      height: 18rem
</style>
