<template>
  <modal-wrapper :visible="visible" v-on="$listeners">
    <template slot="content">
      <div class="modal-light-title">{{ content.title }}</div>
      <div class="modal-light-description">{{ content.description }}</div>
      <a
        v-for="contact in contacts"
        :key="contact.text"
        :href="contact.href"
        class="contact flex-v-center"
      >
        <div
          :style="{
            'background-image': 'url(' + imageUrl(contact.iconLight) + ')',
          }"
          class="contact__icon"
        />
        <div class="contact__text">{{ contact.text }}</div>
      </a>
      <div class="flex-center">
        <main-button
          :title="content.accept"
          theme="light"
          class="button"
          @button-clicked="$emit('close')"
        />
      </div>
    </template>
  </modal-wrapper>
</template>

<script>
import ModalWrapper from "@/components/common/Modals/ModalWrapper"
import MainButton from "@/components/common/Form/MainButton"
import { billModal, topMenu } from "@/content/data"
import { imageUrl } from "@/helpers/imageLoading"

export default {
  name: "ModalBill",
  components: {
    ModalWrapper,
    MainButton,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      content: billModal,
      contacts: topMenu.contacts,
      imageUrl,
    }
  },
}
</script>

<style lang="sass" scoped>
.contact
  margin-top: 4rem
  text-decoration: none
  .contact__icon
    width: 2.4rem
    height: 2.4rem
    background-position: center
    background-size: cover
  .contact__text
    margin-left: 0.8rem
    color: $secondaryBlack
    font-size: 2.4rem
    font-weight: 600
    @media (max-width: 693px)
      font-size: 1.9rem
    @media (min-width: 694px) and (max-width: 767px)
      font-size: 1.7rem
    @media (min-width: 768px) and (max-width: 1023px)
      font-size: 1.9rem
.button
  margin-top: 5rem
  width: 100%
  height: 48px
</style>
