<template>
  <header
    :style="{ 'background-image': 'url(' + currentImage + ')' }"
    class="header"
  >
    <div class="header__title" v-html-safe="content.title" />
    <div class="font2-18-24 header__description">{{ content.description }}</div>
    <div
      v-for="(option, index) in content.options"
      :key="index"
      class="header__option"
    >
      <div
        :style="{ 'background-image': 'url(' + imageUrl(option.icon) + ')' }"
        class="header__option-icon"
      />
      <div class="header__option-text">{{ option.text }}</div>
    </div>
  </header>
</template>

<script>
import { headerBlock } from "@/content/data"
import { imageUrl } from "@/helpers/imageLoading"
import resizeMixin from "@/mixins/resizeMixin"

export default {
  name: "HeaderComponent",
  mixins: [resizeMixin],
  data() {
    return {
      content: headerBlock,
      imageUrl,
    }
  },
}
</script>

<style lang="sass" scoped>
.header
  margin-bottom: $desktopVerticalPaddings
  padding: 19.6rem $desktopHorizontalPaddings 9.1rem
  background-position: center
  background-size: cover
  @media (max-width: 1023px)
    margin-bottom: $mobileVerticalPaddings
    padding: 11.1rem $mobileHorizontalPaddings 22.5rem
  &__title
    margin-bottom: 2.4rem
    font-size: 7.6rem
    line-height: 8.5rem
    font-weight: 500
    letter-spacing: -0.005em
    @media (max-width: 1023px)
      margin-bottom: 1.6rem
      font-size: 3.2rem
      line-height: 4rem
  &__description
    width: 51.6rem
    margin-bottom: 10.2rem
    color: $textSecondary
    @media (max-width: 767px)
      width: 100%
      margin-bottom: 3.2rem
  &__option
    display: flex
    align-items: center
    width: 36.9rem
    height: 8rem
    margin-top: 3rem
    padding-left: 2.4rem
    background: rgba(53, 84, 76, 0.43)
    border: 1px solid rgba(211, 255, 233, 0.06)
    backdrop-filter: blur(7.5px)
    border-radius: 20px
    @media (max-width: 767px)
      width: auto
      height: 4rem
      padding-right: 1.4rem
      padding-left: 1.2rem
      margin-top: 1.2rem
      margin-right: 3.2rem
    &-icon
      width: 2.8rem
      height: 2.8rem
      margin-right: 2.4rem
      background-position: center
      background-size: cover
      @media (max-width: 767px)
        width: 1.6rem
        height: 1.6rem
        margin-right: 1.2rem
    &-text
      font-family: $secondaryFont
      font-size: 1.6rem
      line-height: 2.2rem
      color: $textSecondary
      @media (max-width: 767px)
        font-size: 1.2rem
        line-height: 1.6rem
</style>
