<template>
  <div id="app">
    <router-view />
    <modal-error />
  </div>
</template>

<script>
import { mapMutations } from "vuex"
import modalError from "@/components/common/Modals/ModalError"
export default {
  name: "App",
  components: { modalError },
  data() {
    return {
      desktopWidth: 1024,
    }
  },
  created() {
    this.handleResize()
    window.addEventListener("resize", this.handleResize)
  },
  methods: {
    ...mapMutations({
      setMobile: "setIsMobile",
    }),
    handleResize() {
      return window.innerWidth < this.desktopWidth
        ? this.setMobile(true)
        : this.setMobile(false)
    },
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize)
  },
}
</script>
