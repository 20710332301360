<template>
  <section class="mobile light-scheme flex">
    <div class="mobile__header flex-between-center">
      <div class="mobile__header-logo">Порт-Транзит</div>
      <div class="flex-v-center">
        <main-button
          theme="passive"
          title="Выйти"
          class="mobile__header-button"
          @button-clicked="showExitModal = true"
        />
        <div class="mobile__header-hamburger flex-center" @click="showDropdown">
          <img
            v-if="isDropdownHidden"
            src="@/assets/img/phone-light.svg"
            alt="menu"
          />
          <img v-else src="@/assets/img/menu-close-light.svg" alt="menu" />
        </div>
      </div>
    </div>
    <div :class="['mobile__dropdown', { hidden: isDropdownHidden }]">
      <div>
        <a
          v-for="contact in header"
          :key="contact.text"
          :href="contact.href"
          class="dropdown__contact flex-v-center"
        >
          <div
            :style="{
              'background-image': 'url(' + imageUrl(contact.icon) + ')',
            }"
            class="dropdown__contact-icon"
          />
          <div class="dropdown__contact-text">{{ contact.text }}</div>
        </a>
      </div>
      <div class="dropdown__footer">
        <div class="dropdown__footer-text">
          {{ footer.copyright }}&nbsp;{{ year }}
        </div>
        <a href="/policy.pdf" target="_blank" class="dropdown__footer-link">
          Политика конфиденциальности
        </a>
      </div>
    </div>
    <div class="mobile__content">
      <slot name="content" />
    </div>
    <div class="mobile__footer flex-center">
      <slot name="footer" />
    </div>
    <slot name="modal" />
    <modal-small
      :visible="showExitModal"
      :content="exitModal"
      @close="showExitModal = false"
      @confirm="logout"
    />
  </section>
</template>

<script>
import layoutMixin from "@/layouts/layoutMixin"
export default {
  name: "MobileCabinetLayout",
  mixins: [layoutMixin],
  data() {
    return {
      isDropdownHidden: true,
    }
  },
  methods: {
    showDropdown() {
      this.isDropdownHidden = !this.isDropdownHidden
      // запрет прокрутки фонового элемента на время открытия меню
      document.body.style.overflow = this.isDropdownHidden ? "auto" : "hidden"
    },
  },
}
</script>

<style lang="sass" scoped>
@import "@/assets/styles/scroll-mixin.sass"
.mobile
  position: absolute
  top: 0
  left: 0
  right: 0
  bottom: 0
  flex-direction: column
  overflow: hidden
  &__header
    flex-shrink: 0
    height: 6rem
    padding: 0 2.4rem
    background: $textWhite
    border-bottom: 1px solid $scrollbarGray
    z-index: 110
    &-logo
      font-size: 1.8rem
      font-weight: 700
    &-button
      width: 11.3rem
      height: 3.2rem
      margin-right: 2rem
    &-hamburger
      width: 3.2rem
      height: 3.2rem
      border: 1px solid $special
      border-radius: 10px
      cursor: pointer
      img
        width: 1.6rem
        height: 1.6rem
        object-fit: cover

  &__dropdown
    position: fixed
    top: 0
    left: 0
    right: 0
    display: flex
    flex-direction: column
    justify-content: space-between
    height: 100vh
    height: -webkit-fill-available
    padding: 10rem 2.4rem 6rem
    background: $textSecondary
    transition: transform .5s ease
    z-index: 105
    &.hidden
      transform: translateY(-100%)
    .dropdown__contact
      margin-bottom: 2rem
      text-decoration: none
      &-icon
        width: 1.6rem
        height: 1.6rem
        background-position: center
        background-size: cover
      &-text
        margin-left: 1.2rem
        font-size: 1.6rem
        line-height: 2.2rem
        font-weight: 500
        color: $primaryBlack
    .dropdown__footer
      font-family: $secondaryFont
      font-size: 1.2rem
      color: $tertiaryBlack
      &-text
        margin-bottom: 1.6rem
      &-link
        text-decoration: none
        color: $tertiaryBlack

  &__content
    @include scrollbar
    flex: 1
    overflow: auto
  &__footer
    flex-shrink: 0
    height: 8rem
    background: $textWhite
    padding: 2.4rem
    border-top: 1px solid $scrollbarGray
    z-index: 10
</style>
