<template>
  <component :is="currentView" />
</template>

<script>
import DesktopTrackView from "@/views/TrackPage/DesktopTrackView"
import MobileTrackView from "@/views/TrackPage/MobileTrackView"
// import MobileComponentEmpty from "@/views/TrackPage/MobileComponentEmpty"
import { mapGetters } from "vuex"
export default {
  name: "TrackPage",
  computed: {
    ...mapGetters({
      isMobile: "getIsMobile",
    }),
    currentView() {
      return this.isMobile ? MobileTrackView : DesktopTrackView
      // return this.isMobile ? MobileComponentEmpty : DesktopTrackView
    },
  },
}
</script>
