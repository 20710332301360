import MainButton from "@/components/common/Form/MainButton"
import ModalTrack from "@/components/common/Modals/ModalTrack"
import TruckCard from "@/components/TruckCard/TruckCard"
import GlonassEmpty from "@/components/glonass/GlonassEmpty"
import YandexMapComponent from "@/components/YandexMap/YandexMapComponent"
import { emptyBlock } from "@/content/data"
import Api from "@/api"
import { setTrucks } from "@/helpers/storage"

export default {
  components: {
    MainButton,
    ModalTrack,
    TruckCard,
    YandexMapComponent,
    GlonassEmpty,
  },
  data() {
    return {
      truckList: [],
      loading: false,
      activeTruck: "",
      hoveredTruck: "",
      modalTrackOpened: false,
      emptyText: emptyBlock.title,
    }
  },
  computed: {
    plateList() {
      return this.truckList.map((item) => ({ value: item.auto_num }))
    },
    mapList() {
      let notBlocked = this.truckList.filter(
        (item) => item.status !== "blocked"
      )
      return notBlocked.map((item) => ({
        id: item.auto_num,
        coords: [Number(item.coord.latitude), Number(item.coord.longitude)],
      }))
    },
  },
  async created() {
    await this.fetchTrucks()
  },
  methods: {
    async fetchTrucks() {
      this.loading = true
      try {
        const { data } = await Api.main()
        this.truckList = data.data.auto_info_list
        setTrucks(this.truckList)
      } catch (e) {
        this.$store.commit("setIsError", true)
      } finally {
        this.loading = false
      }
    },
    startTrack() {
      this.modalTrackOpened = true
    },
    // изменение Глонасс-статуса машины в списке
    async setTruckStatus() {
      this.activeTruck = ""
      this.hoveredTruck = ""
      await this.fetchTrucks()
    },
  },
}
