import { topMenu, footerBlock, exitModal } from "@/content/data"
import { imageUrl } from "@/helpers/imageLoading"
import MainButton from "@/components/common/Form/MainButton"
import ModalSmall from "@/components/common/Modals/ModalSmall"
import { mapActions } from "vuex"
import { removeUser, removeTrucks } from "@/helpers/storage"

export default {
  components: { MainButton, ModalSmall },
  data() {
    return {
      header: topMenu.contacts,
      footer: footerBlock,
      showExitModal: false,
      imageUrl,
      exitModal,
    }
  },
  computed: {
    year() {
      let date = new Date()
      return date.getFullYear()
    },
  },
  methods: {
    ...mapActions({
      setProfile: "setUser",
    }),
    logout() {
      removeUser()
      removeTrucks()
      this.setProfile("")
      this.$router.push({ name: "home" })
    },
  },
}
