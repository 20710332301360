import axios from "axios"
import store from "@/store"

const URL = {
  login: "/auth/login",
  confirm: "/auth/confirm-phone",
  main: "/auto/own",
  track: "/auto/get-track",
  blockTruck: "/auto/block",
  unblockTruck: "/auto/unblock",
}

const Request = axios.create({
  // eslint-disable-next-line no-undef
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 60000,
})

Request.interceptors.request.use((config) => {
  const token = store.getters.getUser
  const newConfig = config

  if (token) {
    newConfig.headers.Authorization = `Bearer ${token}`
  }

  return newConfig
})

export default {
  login(data) {
    return Request.post(URL.login, data)
  },
  confirm(data) {
    return Request.post(URL.confirm, data)
  },
  main() {
    return Request.get(URL.main)
  },
  track(data) {
    let params = ""
    for (let key in data) {
      params += params.length ? "&" : "?"
      params += `${key}=${data[key]}`
    }
    return Request.get(URL.track + params)
  },
  blockTruck(truck) {
    return Request.post(URL.blockTruck, { num_auto: truck })
  },
  unblockTruck(truck) {
    return Request.post(URL.unblockTruck, { num_auto: truck })
  },
}
