<template>
  <div>
    <div class="content__title">{{ content.title }}</div>
    <div class="font2-16-22 content__description">
      {{ content.description }}
      <br />
      {{ login.phone_number }}
    </div>
    <!--? Убрать код перед переходом в прод!!!!    -->
    <div class="font2-16-22 content__description">
      Код подтверждения - {{ receivedCode }}
    </div>
    <div class="font2-16-22 content__back" @click="$emit('change-phone')">
      {{ content.back }}
    </div>
    <form v-loading="loading" name="contacts" class="form-login">
      <double-input
        :title="content.inputs.code.title"
        :placeholder="content.inputs.code.placeholder"
        :has-error="hasCodeError"
        :error-text="codeError"
        theme="dark"
        class="form-login__input"
        @input-focus="hasCodeError = false"
        @input-changed="code = $event"
      />
      <main-button
        :title="content.button"
        class="form-login__button"
        @button-clicked="getUser"
      />
      <timer-button
        :timer-interval="timerDelay"
        :texts="content.timer"
        @resend-code="resendCode"
      />
    </form>
  </div>
</template>

<script>
import DoubleInput from "@/components/common/Form/DoubleInput"
import MainButton from "@/components/common/Form/MainButton"
import TimerButton from "@/components/common/Form/Auth/TimerButton"
import { formData } from "@/content/data"
import { setUser } from "@/helpers/storage"
import { mapActions } from "vuex"
import Api from "@/api"
export default {
  name: "ConfirmForm",
  components: { DoubleInput, MainButton, TimerButton },
  props: {
    login: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      content: formData.confirm,
      code: "",
      hasCodeError: false,
      codeError: "",
      loading: false,
      timerDelay: 0,
      //? Убрать код перед переходом в прод!!!!
      receivedCode: "",
    }
  },
  computed: {
    hasValidationErrors() {
      return this.hasCodeError
    },
  },
  created() {
    this.timerDelay = this.login.timerDelay || 30
    //? Убрать код перед переходом в прод!!!!
    this.receivedCode = this.login.code
  },
  methods: {
    ...mapActions({
      setProfile: "setUser",
    }),
    validateData() {
      if (this.code.length !== this.content.codeLength) {
        this.hasCodeError = true
        this.codeError = this.code.length
          ? this.content.inputs.code.errors.format
          : this.content.inputs.code.errors.empty
      }
    },

    async getUser() {
      this.hasCodeError = false
      this.validateData()

      if (!this.hasValidationErrors) {
        let formData = new FormData()
        formData.append("phone_number", this.login.phone_number)
        formData.append("code", this.code)
        this.loading = true
        try {
          const { data } = await Api.confirm(formData)
          setUser(data.data.token)
          this.setProfile(data.data.token)
          await this.$router.push({ name: "glonass" })
        } catch (e) {
          this.hasCodeError = true
          this.codeError = e.response.data.error
        } finally {
          this.loading = false
        }
      }
    },
    async resendCode() {
      let formData = new FormData()
      formData.append("num_auto", this.login.num_auto)
      formData.append("phone_number", this.login.phone_number)
      this.loading = true
      try {
        const { data } = await Api.login(formData)
        this.$message({
          message: "Новый код успешно отправлен",
          type: "success",
        })
        //? Убрать код перед переходом в прод!!!!
        this.receivedCode = data.data.code
      } catch (e) {
        this.$store.commit("setIsError", true)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style lang="sass" scoped>
@import "./auth-styles"
.content__description
  margin-bottom: 1.2rem
.content__back
  margin-bottom: 2.8rem
  color: $textAccent
  cursor: pointer
</style>
