<template>
  <section v-loading="loading" class="route">
    <yandex-map
      :settings="ymapSettings"
      zoom="7"
      :coords="center"
      :controls="controls"
      @map-was-initialized="mapLoaded"
    />
  </section>
</template>

<script>
import { MAP_CENTER, ymapSettings, multiRouteOptions } from "./constants"
import { yandexMap } from "vue-yandex-maps"
import { dateFromUnix } from "@/helpers/date"
export default {
  name: "YandexOneRoute",
  components: { yandexMap },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      center: MAP_CENTER,
      controls: ["zoomControl"],
      zoom: 7,
      points: [],
      ymapSettings,
      loading: true,
      map: null,
    }
  },
  methods: {
    mapLoaded(e) {
      e.options.set("suppressMapOpenBlock", true)
      this.map = e
      if (window.ymaps && this.map) {
        this.points = this.list.map((item) => [item.latitude, item.longitude])
        // установка параметров маршрута
        let multiRoute = new window.ymaps.multiRouter.MultiRoute(
          {
            referencePoints: this.points,
            params: { results: 1 },
          },
          multiRouteOptions
        )

        multiRoute.model.events.once("requestsuccess", () => {
          // Создаем хинты всем меткам
          this.list.forEach((item, index) => {
            let yandexWayPoint = multiRoute.getWayPoints().get(index)
            window.ymaps.geoObject.addon.balloon.get(yandexWayPoint)
            yandexWayPoint.options.set({
              balloonLayout: window.ymaps.templateLayoutFactory.createClass(
                `<div class="my-balloon">
                <div class="my-balloon__info">${dateFromUnix(item.time)}</div>
                <div class="my-balloon__placemark" />
                </div> `
              ),
              closeButton: false,
            })
          })
        })

        // Добавление маршрута на карту
        this.map.geoObjects.add(multiRoute)
        multiRoute.events.once("update", this.stopLoading)
      }
    },
    stopLoading() {
      setTimeout(() => (this.loading = false), 1000)
    },
  },
}
</script>

<style lang="sass" scoped>
.route,
.ymap-container
  height: 100%

::v-deep .my-balloon
  position: absolute
  display: flex
  flex-direction: column
  align-items: center
  left: -35px
  top: -45px
  &__info
    position: relative
    width: 70px
    padding: 6px 0
    text-align: center
    font-family: $secondaryFont
    font-size: 14px
    font-weight: 700
    line-height: 18px
    background: $special
    color: $textWhite
    border-radius: 6px
    &::after
      content: ""
      position: absolute
      bottom: -14px
      left: 50%
      transform: translateX(-50%)
      border: 6px solid transparent
      border-top: 12px solid $special
  &__placemark
    width: 14px
    height: 14px
    margin-top: 8px
    border-radius: 50%
    background: $textWhite
    border: 3px solid $special
</style>
