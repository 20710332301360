<template>
  <div :class="['timer', isDisabled ? 'counting' : 'active']" @click="clicked">
    {{ buttonTitle }}
  </div>
</template>

<script>
export default {
  name: "TimerButton",
  props: {
    timerInterval: {
      type: Number,
      default: 30,
    },
    texts: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isButtonTimer: true,
      timerId: null,
      timerValue: 0,
    }
  },
  computed: {
    buttonTitle() {
      if (this.isButtonTimer) {
        let minutes = Math.trunc(this.timerValue / 60)
        let minutesText = minutes < 10 ? "0" + String(minutes) : minutes
        let seconds = this.timerValue % 60
        let secondsText = seconds < 10 ? "0" + String(seconds) : seconds

        return `${this.texts.wait} ${minutesText}:${secondsText}`
      }
      return this.texts.resend
    },
    isDisabled() {
      return this.isButtonTimer
    },
  },
  mounted() {
    this.startTimer()
  },
  beforeDestroy() {
    clearInterval(this.timerId)
  },
  methods: {
    startTimer() {
      this.timerValue = this.timerInterval
      this.isButtonTimer = true
      this.timerId = setInterval(() => {
        this.timerValue--
      }, 1000)
      setTimeout(() => {
        clearInterval(this.timerId)
        this.isButtonTimer = false
      }, this.timerValue * 1000)
    },
    clicked() {
      if (!this.isDisabled) {
        this.startTimer()
        this.$emit("resend-code")
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.timer
  width: fit-content
  margin: 2.4rem auto 0
  font-size: 1.4rem
  line-height: 1.6rem
  font-weight: 500
  &.counting
    color: $commentColor
  &.active
    color: $textAccent
    cursor: pointer
</style>
