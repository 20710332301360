import moment from "moment-timezone"

moment.tz.setDefault("Europe/Moscow")
moment.locale("ru")
export default moment

export const isFuture = (date) => {
  return moment(date).isAfter(moment(), "day")
}

export const dateFromUnix = (date) => {
  return moment(date, "x").format("HH:mm")
}

export const dateRuFromQuery = (date) => {
  return moment(date, "YYYY-MM-DD").format("DD MMMM YYYY") + " г."
}
