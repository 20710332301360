import LandingPage from "@/views/LandingPage"
import GlonassPage from "@/views/GlonassPage/GlonassPage"
import TrackPage from "@/views/TrackPage/TrackPage"

const routes = [
  {
    path: "/",
    name: "home",
    component: LandingPage,
  },
  {
    path: "/glonass",
    name: "glonass",
    component: GlonassPage,
  },
  {
    path: "/track",
    name: "track",
    component: TrackPage,
  },
  {
    path: "*",
    redirect: { name: "home" },
  },
]

export default routes
