import { getUser } from "@/helpers/storage"

export default {
  state: {
    user: getUser(),
  },
  getters: {
    getUser(state) {
      return state.user
    },
  },
  mutations: {
    SET_USER(state, user) {
      state.user = user
    },
  },
  actions: {
    setUser({ commit }, user) {
      commit("SET_USER", user)
    },
  },
}
