<template>
  <div class="layout light-scheme">
    <header class="layout__header flex-between-center">
      <div class="layout__header-logo">Порт-Транзит</div>
      <div class="flex-v-center">
        <a
          v-for="contact in header"
          :key="contact.text"
          :href="contact.href"
          class="layout__header-contact flex-v-center"
        >
          <div
            :style="{
              'background-image': 'url(' + imageUrl(contact.iconLight) + ')',
            }"
            class="contact__icon"
          />
          <div class="contact__text">{{ contact.text }}</div>
        </a>
        <div>
          <slot name="button" />
        </div>
        <main-button
          title="Выйти"
          theme="passive"
          class="layout__header-exit"
          @button-clicked="showExitModal = true"
        />
      </div>
    </header>
    <section class="layout__page flex">
      <aside class="layout__page-aside">
        <slot name="aside" />
        <div class="aside__footer flex">
          <div>{{ footer.copyright }}&nbsp;{{ year }}</div>
          <a href="/policy.pdf" target="_blank" class="aside__footer-link">
            Политика конфиденциальности
          </a>
        </div>
      </aside>
      <main class="layout__page-main">
        <slot name="main" />
      </main>
    </section>
    <slot name="modal" />
    <modal-small
      :visible="showExitModal"
      :content="exitModal"
      @close="showExitModal = false"
      @confirm="logout"
    />
  </div>
</template>

<script>
import layoutMixin from "@/layouts/layoutMixin"
export default {
  name: "DesktopCabinetLayout",
  mixins: [layoutMixin],
}
</script>

<style lang="sass" scoped>
@import "@/assets/styles/scroll-mixin.sass"
.layout
  height: 100vh
  min-height: 100vh
  min-height: -webkit-fill-available
  overflow: hidden
  .layout__header
    height: 7rem
    padding: 0 4rem
    border-bottom: 1px solid $scrollbarGray
    &-logo
      font-size: 2.2rem
      font-weight: 500
    &-contact
      margin-left: 3.3rem
      text-decoration: none
      .contact__icon
        width: 1.6rem
        height: 1.6rem
        background-position: center
        background-size: cover
      .contact__text
        margin-left: 0.8rem
        color: $secondaryBlack
        font-size: 1.6rem
        font-weight: 500
        letter-spacing: -0.005em
    &-exit
      margin-left: 2rem
      padding: 1.2rem 2.4rem
      font-size: 1.4rem

  &__page
    height: calc(100vh - 7rem)
    &-aside
      flex-shrink: 0
      width: 481px
      height: 100%
      border-right: 1px solid $scrollbarGray
      overflow: auto
      @include scrollbar

      .aside__footer
        position: fixed
        bottom: 0
        left: 0
        justify-content: space-between
        width: 481px
        height: 62px
        padding: 16px 40px
        font-family: $secondaryFont
        font-size: 12px
        background: $textSecondary
        color: $tertiaryBlack
        border-top: 1px solid $scrollbarGray
        border-right: 1px solid $scrollbarGray
        &-link
          color: $tertiaryBlack
          text-decoration: none

    &-main
      flex: 1
</style>
