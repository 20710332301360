<template>
  <component :is="currentView" class="light" />
</template>

<script>
import DesktopGlonassView from "@/views/GlonassPage/DesktopGlonassView"
import MobileGlonassView from "@/views/GlonassPage/MobileGlonassView"
import { mapGetters } from "vuex"

export default {
  name: "GlonassPage",
  computed: {
    ...mapGetters({
      isMobile: "getIsMobile",
    }),
    currentView() {
      return this.isMobile ? MobileGlonassView : DesktopGlonassView
    },
  },
}
</script>
